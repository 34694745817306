import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import APIMan from "../../data/APIMan";
import { DashBox } from "../home_pages/Home";
import Pageloader, { PageLoaderImage } from "../Pageloader";

const Site = (props) => {

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState("");
    const { siteID } = useParams();
    const [saveError, setSaveError] = useState("");

    const [siteName, setSiteName] = useState("");
    const [siteURL, setSiteURL] = useState("");
    const [siteMatomoID, setSiteMatomoID] = useState("");
    const [siteMatomoToken, setSiteMatomoToken] = useState("");

    const history = useHistory();

    useEffect(() => {
        updateView();
    }, [props, siteID]);

    const updateView = () => {
        setLoading(true);
        setError('');
        var token = localStorage.getItem('auth_token') || "unset";
        APIMan.sendRequest('getsite.php?auth_token=' + token + '&site=' + siteID, result => {
            if(result.error) {
                setError(result.errorMessage);
            }else {
                setError('');
                setSiteName(result.data.site.name);
                setSiteURL(result.data.site.url);
                setSiteMatomoID(result.data.site.matomoid);
                setSiteMatomoToken(result.data.site.matomotoken);
            }
            setLoading(false);
        });
    };

    const save = () => {
        setSaveError("");
        setLoading(true);
        var token = localStorage.getItem('auth_token') || "unset";
        APIMan.sendRequest('savesite.php?auth_token=' + token
        + '&site=' + siteID + '&name=' + siteName + '&url=' + siteURL + '&matomosite=' + 
        siteMatomoID + '&matomotoken=' + siteMatomoToken, result => {
            if(result.error) {
                setSaveError(result.errorMessage);
            }else {
                setSaveError('');
            }
            setLoading(false);
        });
    }

    const deleteSite = () => {
        setLoading(true);
        var token = localStorage.getItem('auth_token') || "unset";
        APIMan.sendRequest('deletesite.php?auth_token=' + token + '&site=' + siteID, result => {
            if(result.error) {
                setError(result.errorMessage);
                setLoading(false);
            }else {
                history.push('/profile/sites');
            }
        });
    }

    if(loading) {
        return <PageLoaderImage />
    }

    if(error !== "") {
        return <>
            <div className="alert error">{error}</div>
        </>
    }

    return <>

        <div className="flex flex-between">
            <div>
                <h1>Seiten&shy;verwaltung</h1>
            </div>
            <div>
                <button className="btn simple" onClick={save}>Speichern</button>
            </div>
        </div>

        {saveError !== "" && 
            <div className="alert error">{saveError}</div>
        }

        <div className="flex-grid">

            <div className="col">
                <DashBox>
                    <h2>Allgemein</h2>

                    <div className="formfield">
                        <input type="text" id="sitename" autoComplete="off"
                            value={siteName} onInput={e => setSiteName(e.currentTarget.value)} required />
                        <label htmlFor="sitename">Name</label>
                    </div>

                    <div className="formfield">
                        <input type="text" id="siteurl" autoComplete="off"
                            value={siteURL} onInput={e => setSiteURL(e.currentTarget.value)} required />
                        <label htmlFor="siteurl">URL</label>
                    </div>
                </DashBox>
            </div>

            <div className="col">
                <DashBox>
                    <h2>Matomo</h2>

                    <div className="formfield">
                        <input type="text" id="matomosite" autoComplete="off"
                            value={siteMatomoID} onInput={e => setSiteMatomoID(e.currentTarget.value)} required />
                        <label htmlFor="matomosite">Matomo Seiten-ID</label>
                    </div>

                    <div className="formfield">
                        <input type="text" id="matomotoken" autoComplete="off"
                            value={siteMatomoToken} onInput={e => setSiteMatomoToken(e.currentTarget.value)} required />
                        <label htmlFor="matomotoken">Matomo API-Token</label>
                    </div>
                </DashBox>
            </div>

        </div>

        <br />

        <h2>Weitere Aktionen</h2>

        <DashBox>
            <p>
                <b>ACHTUNG:</b> Die Seite wird sofort vollständig gelöscht! Diese Aktion kann nicht 
                Rückgängig gemacht werden! Es ist keine weitere Bestätigung zum löschen erforderich.
            </p>

            <button className="btn simple" onClick={deleteSite}>Seite löschen</button>
        </DashBox>
    
    </>

}

export default Site;