import { useState } from "react";
import { useParams } from "react-router-dom"
import APIMan from "../data/APIMan";
import Report from "./home_pages/Report";

const ViewValidation = (props) => {

    const { reportID } = useParams();

    const [allowed, setAllowed] = useState(false);
    const [sitedomain, setSitedomain] = useState("");
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");

    const login = () => {
        setError("");
        setLoading(true);
        APIMan.sendRequest('validateview.php?reportID=' + reportID 
        + '&siteDomain=' + sitedomain, result => {
            if(result.error) {
                setAllowed(false);
                setError(result.errorMessage);
            }else {
                setAllowed(true);
            }
            setLoading(false);
        });
    }

    if(allowed) {
        return <Report viewOnly={true} />
    }

    return <>
    
        <div id="loginscreen">

            <div className={"loginbox" + (loading ? " loading" : "")}>

                <center>
                    <img src="https://www.krausskommunikation.de/wp-content/themes/krauss2020/images/logo.svg" />
                    <br />
                    <h3>Report ansehen</h3>
                </center>

                <br />

                {error !== "" && <div className="error">{error}</div>}

                <p>
                    Bitte geben Sie zur Verifizierung die Domain Ihrer Website ein, um den Report 
                    anzusehen.
                </p>

                <div className="formfield">
                    <input type="text" id="domain" name="domain" autoComplete="off"
                        value={sitedomain} onInput={e => setSitedomain(e.currentTarget.value)} required />
                    <label for="username">Domain</label>
                </div>

                <br />

                <button className="btn block" onClick={login}>Bestätigen</button>

                <br />

                <p style={{opacity: "0.3"}}>
                    <small>
                        <i>
                            Damit nur Sie Ihren Report ansehen können, müssen Sie zur Bestätigung die Domain 
                            der Website angeben, für die der Report erstellt wurde. Wenn Sie diesen Report 
                            für die Website <b>meine-website.de</b> erhalten haben, geben 
                            Sie <b>www.meine-website.de</b> ein.
                        </i>
                    </small>
                </p>

            </div>

        </div>
    
    </>

}

export default ViewValidation;