export default {

    apiurl: 'https://api.monitoring.krauss-webstats.de/',
    sendRequest: function(url, callback) {
        fetch(this.apiurl + url).then(data => data.json()).then(json => {
            if(json.meta.code === "200") {
                callback({
                    error: false,
                    errorMessage: 'Ok.',
                    data: json.data
                });
            }else {
                callback({
                    error: true,
                    errorMessage: json.meta.output,
                    data: {}
                });
            }
        }).catch(error => {
            callback({
                error: true,
                errorMessage: 'Ein Fehler ist aufgetreten.',
                data: {}
            });
        });
    }

}