import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom"
import Modal, { ModalCloser, ModalContent } from "../Modal";
import { PageLoaderImage } from "../Pageloader";
import Tooltip from "../Tooltip";
import SupportWindow from "../support_pages/SupportWindow";
import APIMan from "../../data/APIMan";

const Reports = (props) => {

    const [reports, setReports] = useState([]);
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(true);

    const [modalOpen, setModalOpen] = useState(false);
    const [modalLoading, setModalLoading] = useState(false);

    const [nrStart, setNrStart] = useState(0);
    const [nrEnd, setNrEnd] = useState(0);
    const [nrError, setNrError] = useState('');

    const [nextSites, setNextSites] = useState([])

    const { siteID } = useParams();

    useEffect(() => {
        reloadTable();    
    }, [props, siteID]);

    const reloadTable = () => {
        setLoading(true);
        setError('');
        setReports([]);
        var token = localStorage.getItem('auth_token') || "unset";
        var site = localStorage.getItem('csid') || "unset";
        APIMan.sendRequest('getreports.php?auth_token=' + token
        + '&site_token=' + site + '&offset=' + ((siteID-1) * 20) + '&amount=20', result => {
            if(result.error) {
                setError(result.errorMessage);
            }else {
                setError('');
                setReports(result.data.reports);
                setNextSites(result.data.sites);
            }
            setLoading(false);
        });
    }

    const createReport = () => {
        setModalLoading(true);
        var token = localStorage.getItem('auth_token') || "unset";
        var site = localStorage.getItem('csid') || "unset";
        APIMan.sendRequest('createreport.php?auth_token=' + token
        + '&site_token=' + site + '&start=' + nrStart + '&end=' + nrEnd, result => {
            if(result.error) {
                setNrError(result.errorMessage);
            }else {
                setNrError('');
                setModalOpen(false);
                reloadTable();
            }
            setModalLoading(false);
        });
    }

    if(loading) {
        return <> <PageLoaderImage /> </>
    }

    if(error !== "") {
        return <>
            <div className="alert error">{error}</div>        
        </>
    }

    return <>
 
    <div className="flex flex-between">
        <h1>Reports</h1>
        <button className="btn add" onClick={() => setModalOpen(true)}>Neuer Report</button>
    </div>

    <p>
        Hier finden Sie eine Übersicht über 
        alle <Tooltip content={'Dazu gehören manuell und automatisch erstellte.'}>
        erstellten Reports</Tooltip> zu der ausgewählten Seite.
    </p>

    <p>
        <SupportWindow link="/support/reports">Mehr Informationen über Reports</SupportWindow>
    </p>

    <div className="table">
    <table>
        <thead>
            <tr>
                <td>#</td>
                <td>Von</td>
                <td></td>
                <td>Bis</td>
                <td>Bezeichnung</td>
                <td></td>
            </tr>
        </thead>
        <tbody>
            {reports.length >= 0 && reports.map(report => (
                <tr key={report.id}>
                    <td>{report.id}</td>
                    <td>{report.start}</td>
                    <td><span className="duration">{report.duration}</span></td>
                    <td>{report.end}</td>
                    <td><Link to={(report.type === "3" ? '/home/reports/' : '/home/reports/' + report.token)}>{report.name}</Link></td>
                    {report.type === "0" && 
                        <td><span className="report-state state-0">Wird erstellt</span></td>}
                    {report.type === "1" && 
                        <td><span className="report-state state-1">Automatisch</span></td>}
                    {report.type === "2" && 
                        <td><span className="report-state state-2">Manuell</span></td>}
                    {report.type === "3" && 
                        <td><span className="report-state state-3">Fehlerhaft</span></td>}
                </tr>
            ))}
        </tbody>
    </table>
    </div>

    {/**
     * Create new report modal
     */}
    <Modal open={modalOpen} loading={modalLoading} width={500}>
        <ModalCloser onClick={() => setModalOpen(false)} /> 
        <ModalContent>
            <h1>Neuer Report</h1>
            <p>
                Hier können Sie einen Report über einen von Ihnen definierten Zeitraum anlegen.<br />
                Beachten Sie, dass die Erstellung eines Reports mehrere Minuten dauern kann.
            </p>

            <p>
                Bitte wählen Sie den Zeitraum des Reports:
            </p>

            <br />

            <div className="flex flex-between flex-inline flex-spacing">
                <div className="formfield">
                    <input type="date" value={nrStart} id="rpstart"
                        onInput={e => setNrStart(e.currentTarget.value)} />
                    <label htmlFor="rpstart">Start</label>
                </div>
                <div className="formfield">
                    <input type="date" value={nrEnd} id="rpend"
                        onInput={e => setNrEnd(e.currentTarget.value)} />
                    <label htmlFor="rpend">Ende</label>
                </div>
            </div>

            {nrError && <div className="alert error">{nrError}</div>}
            
            <br />

            <p>
                <button 
                    className="btn"
                    onClick={() => createReport(0, 0, "")}
                >
                    Report erstellen
                </button>
            </p>
        </ModalContent>
    </Modal>
    
    <Pagination>
        {nextSites.map(index => {
            return <PaginationItem key={index}
                to={'/home/reports/site/' + index}
                active={index === parseInt(siteID)}>
                {index}
            </PaginationItem>
        })}
    </Pagination>

    </>

}

export function Pagination(props) {
    return <>
        <div className="pagination">
            {props.children}
        </div>
    </>
}

export function PaginationItem(props) {
    return <Link to={props.to} className={props.active ? 'active' : ''}>
        {props.children}
    </Link>
}

export default Reports;