import { useEffect, useRef, useState } from "react";
import { } from "react-router-dom";
import Modal, { ModalCloser, ModalContent } from '../Modal';
import { PageLoaderImage } from '../Pageloader';
import APIMan from '../../data/APIMan';
import SupportWindow from "../support_pages/SupportWindow";

const Year = (props) => {

    const [currentYear, setCurrentYear] = useState(0);
    const [tableContent, setTableContent] = useState("Loading...");

    const [dayModalDay, setDayModalDay] = useState(-1);
    const [dayModalLoading, setDayModalLoading] = useState(false);
    const [dayModalContent, setDayModalContent] = useState({});

    const [days, setDays] = useState({});
    const [events, setEvents] = useState({});

    const table = useRef(null);

    const [loading, setLoading] = useState(true);

    const [eventModalOpen, setEventModalOpen] = useState(false);
    const [eventModalLoading, setEventModalLoading] = useState(false);
    const [newTitle, setNewTitle] = useState("");
    const [newContent, setNewContent] = useState("");
    const [newDate, setNewDate] = useState("");
    const [modalError, setModalError] = useState("");

    const depth = 6;

    const zeronumber = (input) => {
        return (input > 9 ? "" + input : "0" + input);
    }

    const dateFromDOY = (day, year = (new Date()).getFullYear()) => {
        day--;
        var date = new Date(year, 0, 1);
        date.setTime(date.getTime() + (1000*3600*24*day));
        return date;
    }

    useEffect(() => {
        setCurrentYear(new Date().getFullYear());
        document.body.onclick = checkTableClick;
        return () => {
            document.body.onclick = () => {};
        }
    }, [props]);

    const checkTableClick = (event) => {
        if(table.current !== null) {
            if(event.path.includes(table.current)) {
                event.path.forEach(item => {
                    if(item.tagName === 'TD' && !item.classList.contains('placeholder')) {
                        setDayModalDay(item.getAttribute('id'));
                        setDayModalLoading(true);
                        var year = item.getAttribute('data-year');
                        var token = localStorage.getItem('auth_token') || 'unset';
                        var site = localStorage.getItem('csid') || 'unset';
                        var date = dateFromDOY(item.getAttribute('id'), year);
                        var day = zeronumber(date.getDate()) + "." + zeronumber(date.getMonth()+1) + "." + date.getFullYear()
                        APIMan.sendRequest('yeardetails.php?auth_token=' + token + '&site_token=' + site + '&day=' + day, result => {
                            if(result.error) {
                                console.error(result.errorMessage);
                            }else {
                                setDayModalContent(result.data.content);
                                setDayModalLoading(false);
                            }
                        });
                        return;
                    }
                })
            }
        }
    }

    const updateView = () => {
        setLoading(true);
        var token = localStorage.getItem('auth_token') || 'unset';
        var site = localStorage.getItem('csid') || 'unset';
        APIMan.sendRequest('yearview.php?auth_token=' + token + '&site_token=' + site + '&year=' + currentYear, result => {
            if(result.error) {
                console.error(result.errorMessage);
            }else {
                var events = result.data;
                var start = new Date(currentYear, 0, 1);
                var end = new Date(currentYear, 11, 31);
                var daysOfYear = [];
                var dayOfYear = 0;
                for (var d = start; d <= end; d.setDate(d.getDate() + 1)) {
                    daysOfYear.push(new Date(d));
                }

                var days = {};
                daysOfYear.forEach((item, index) => {
                    days[index+1] = new Array(depth);
                });

                /**
                 * EVENT CALCULATIONS
                 */
                var eventsizes = {};
                Object.keys(events).forEach(item => {
                    if(events[item].debug) console.log(events[item]);
                    eventsizes = {
                        ...eventsizes,
                        [item]: (events[item].end - events[item].start)
                    }
                })

                var sortedEvents = Object.keys(eventsizes).sort(function(a, b) {return -(eventsizes[a] - eventsizes[b])});

                sortedEvents.forEach(event => {
                    var layer = -1;
                    var layers = new Array(depth);
                    for(var i = events[event].start; i<=events[event].end; i++) {
                        for(var i2 = 0; i2<layers.length; i2++) {
                            if(days[i][i2] === undefined) {
                                if(layers[i2] === undefined) layers[i2] = true;
                            }else {
                                layers[i2] = false;
                            }
                        }
                    }
                    for(var i = 0; i<layers.length; i++) {
                        if(layers[i]) {
                            layer = i+1;
                            break;
                        }
                    }
                    if(layer > 0) {
                        for(var i = events[event].start; i<=events[event].end; i++) {
                            days[i][layer-1] = event;
                        }
                    }else {
                        // impossible to show
                    }
                });
                /**
                 * END CALCULATIONS
                 */

                var tablecontent = "<tr>";
                var now = new Date();
                daysOfYear.forEach(item => {
                    dayOfYear++;
                    if(item.getDate() === 1) {
                        tablecontent += "</tr>";
                        tablecontent += "<tr>";
                    }
                    var dayClasses = '';
                    if(item.getDay() === 6) dayClasses += " saturday";
                    if(item.getDay() === 0) dayClasses += " sunday";
                    if(item.getDate() === now.getDate() && item.getFullYear() === now.getFullYear() &&
                        item.getMonth() === now.getMonth()) dayClasses += " today";
                    tablecontent += "<td class='" + dayClasses + "' id='" + dayOfYear + "' data-year='" + (currentYear || (new Date()).getFullYear()) + "' label='" + zeronumber(item.getDate()) + "." + 
                    zeronumber(item.getMonth()+1) + ".'>";
                    for(var i = 0; i<days[dayOfYear].length; i++) {
                        if(days[dayOfYear][i] === undefined) {
                            tablecontent += '<div class="event-cal-placeholder"></div>';
                        }else {
                            var classes = 'event-cal';
                            if(events[days[dayOfYear][i]].start === dayOfYear) {
                                classes += ' start';
                            }
                            if(events[days[dayOfYear][i]].end === dayOfYear) {
                                classes += ' end';
                            }
                            tablecontent += '<div class="' + classes + '" style="background-color: ' + events[days[dayOfYear][i]].color + '"></div>';
                        }
                    }
                    var nextDayDate = new Date(item.getTime() + (1000*3600*24));
                    if(item.getMonth() < nextDayDate.getMonth()) {
                        if(item.getDate() < 31) {
                            for(var i = item.getDate(); i<31; i++) {
                                tablecontent += '<td class="placeholder"></td>';
                            }
                        }
                    }
                    tablecontent += "</td>";
                });
                tablecontent += "</tr>";
                setDays(days);
                setEvents(events);
                setTableContent(tablecontent);
                setLoading(false);
            }
        });
    }

    useEffect(() => {
        updateView();
    }, [currentYear]);

    const closeModal = () => {
        setDayModalDay(-1);
    }

    const showAddEventWindow = () => {
        var cDay = dayModalDay;
        var cDate = dateFromDOY(cDay, currentYear);
        var cUTC = cDate.getFullYear() + "-" + zeronumber(cDate.getMonth()+1) + "-" + zeronumber(cDate.getDate());
        setNewDate(cUTC);
        setDayModalDay(-1);
        setEventModalOpen(true);
    }

    const createEvent = () => {
        setEventModalLoading(true);
        var token = localStorage.getItem('auth_token') || "unset";
        var site = localStorage.getItem('csid') || "unset";
        APIMan.sendRequest('createevent.php?auth_token=' + token + 
        '&site_token=' + site + '&title=' + encodeURIComponent(newTitle) + '&content=' + 
        encodeURIComponent(newContent) + '&date=' + encodeURIComponent(newDate), result => {
            if(result.error) {
                setModalError(result.errorMessage);
            }else {
                setModalError('');
                setEventModalOpen(false);
                setNewDate("");
                setNewContent("");
                setNewTitle("");
                updateView();
            }
            setEventModalLoading(false);
        });
    }

    var dayOfYear = 0;

    if(loading) return <PageLoaderImage />

    return <>
    
        <div className="formfield">
            <select value={currentYear} id="yearselect" defaultValue={currentYear}
            onChange={e => {
                var val = (e.target).querySelector('option:checked[value]').value;
                setCurrentYear(parseInt(val))
            }}>
                <option value={new Date().getFullYear()-2}>
                    {new Date().getFullYear()-2}
                </option>
                <option value={new Date().getFullYear()-1}>
                    {new Date().getFullYear()-1}
                </option>
                <option value={new Date().getFullYear()}>
                    {new Date().getFullYear()} (aktuell)
                </option>
                <option value={new Date().getFullYear()+1}>
                    {new Date().getFullYear()+1}
                </option>
            </select>
            <label htmlFor="yearselect">Jahr</label>
        </div>

        <div className="year-holder">
            <table className="year-table" dangerouslySetInnerHTML={{__html: tableContent}}
                ref={table}></table>
        </div>

        <Modal open={dayModalDay > -1} width={500} loading={dayModalLoading}>
            <ModalCloser onClick={e => closeModal()} />
            <ModalContent>
                <h1>
                    {zeronumber(dateFromDOY(dayModalDay).getDate()) + "." + 
                    zeronumber(dateFromDOY(dayModalDay).getMonth()+1) + "." + 
                    dateFromDOY(dayModalDay, currentYear).getFullYear()}
                </h1>
                <button className="btn simple" onClick={showAddEventWindow}>Neues Ereignis</button>
                {(!dayModalLoading) && Object.keys(dayModalContent).map(item => (
                    <div className="year-modal-box" style={{backgroundColor: dayModalContent[item].color}} key={item}>
                        <h3>{dayModalContent[item].title}</h3>
                        <a href={dayModalContent[item].url} target="_blank">Ansehen</a>
                    </div>
                ))}
            </ModalContent>
        </Modal>

        <Modal open={eventModalOpen} loading={eventModalLoading} width={500}>
            <ModalCloser onClick={() => setEventModalOpen(false)} />
            <ModalContent>
                <h1>Neues Ereignis</h1>
                <p>
                    Um wichtige und relevante Ereignisse festzuhalten, können Tweets, Instagram- und
                    Facebook-Posts,<br /> Newsletter und mehr hier eingetragen werden.
                </p>
                <br />
                <div className="formfield">
                    <input type="text" id="title" value={newTitle} autoComplete="off"
                        onChange={e => setNewTitle(e.currentTarget.value)} required />
                    <label htmlFor="title">Name des Ereignisses</label>
                </div>
                <div className="formfield">
                    <input type="text" id="content" value={newContent} autoComplete="off"
                        onChange={e => setNewContent(e.currentTarget.value)} required />
                    <label htmlFor="content">Inhalt</label>
                </div>
                <p>
                    <small>
                        Social-Media Posts können eingebunden werden, indem nur der Link in das
                        Inhalts-Feld eingefügt wird.
                    </small>
                </p>
                <p>
                    <SupportWindow link="/support/events">
                        Mehr Informationen über Ereignisse
                    </SupportWindow>
                </p>
                <br />
                <div className="formfield">
                    <input type="date" id="date" value={newDate}
                        onChange={e => setNewDate(e.currentTarget.value)} />
                    <label htmlFor="date">Datum</label>
                </div>
                <p>
                    <small>
                        Leerlassen, um das aktuelle Datum zu verwenden.
                    </small>
                </p>
                <br />
                {modalError !== "" && <div className="alert error">{modalError}</div>}
                <button className="btn" onClick={createEvent}>
                    Ereigniss eintragen
                </button>
            </ModalContent>
        </Modal>

    </>

}

export default Year;