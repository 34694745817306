import { useEffect, useState } from "react";
import { PageLoaderImage } from "../Pageloader";
import { DashBox } from '../home_pages/Home';
import UserData from '../../data/UserData';
import APIMan from "../../data/APIMan";

const Profile = (props) => {

    const [uploading, setUploading] = useState(true);
    const [imageError, setImageError] = useState("");
    const [fileHoverOver, setFileHoverOver] = useState(false);

    useEffect(() => {
        setUploading(false);
        window.ondragenter = function(event) {
            setFileHoverOver(true);
        }
        window.ondragexit = function(event) {
            setFileHoverOver(false);
        }
        window.ondragend = function(event) {
            setFileHoverOver(false);
        }
        window.ondragleave = function(event) {
            if (!event.clientX && !event.clientY) {
                setFileHoverOver(false);
             }
        }
        return () => {
            window.ondragenter = function(){}
            window.ondragexit = function(){}
            window.ondragend = function(){}
            window.ondragleave = function(){}
        }
    }, []);

    const onImageChange = (event) => {
        setFileHoverOver(false);

        if(event.target.files.length > 0) {
            setUploading(true);
            var files = Array.from(event.target.files);
            var formData = new FormData();
            formData.set('file', files[0]);
            var token = localStorage.getItem('auth_token') || 'unset';
            fetch(APIMan.apiurl + 'uploadprofileimage.php?auth_token=' + token, {
                method: 'POST',
                body: formData
            }).then(data => data.json()).then(json => {
                console.log(json);
                if(json.meta.code === "200") {
                    setImageError("");
                    window.location.reload();
                }else {
                    setImageError(json.meta.output);
                }
                setUploading(false);
            }).catch(error => {
                setImageError('Ein Fehler ist aufgetreten.');
                setUploading(false);
            })
        }

    }

    if(uploading) return <PageLoaderImage />

    return <>
    
    <div className="flex-grid">
        <div className="col flex-1">
            <DashBox>
                <h2>Profilbild</h2>
                {imageError !== "" && <div className="alert error">{imageError}</div>}
                <br />
                <center>
                    <img src={UserData.userImage} style={{
                        width: '100%', maxWidth: '140px', borderRadius: '50%',
                        border: '3px solid #eee'
                    }} alt="PROFILE" />
                </center>
                <br />
                <div className={"formfield" + (fileHoverOver ? ' file-hover' : '')}>
                    <input type="file" onChange={onImageChange} id="profileimg" />
                    <label htmlFor="profileimg">Profilbild hochladen</label>
                </div>
            </DashBox>
        </div>
        <div className="col flex-2">
            <DashBox>
                <p>Herzlich Willkommen,</p>
                <h2>{UserData.userNickname}</h2>
                <br />
                <div className="table">
                    <table>
                        <thead>
                            <tr>
                                <td></td>
                                <td>Accountinformationen</td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>E-Mail Adresse</td>
                                <td>{UserData.userMail}</td>
                            </tr>
                            <tr>
                                <td>Benutzername</td>
                                <td>{UserData.userName}</td>
                            </tr>
                            <tr>
                                <td>Anzeigename</td>
                                <td>{UserData.userNickname}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </DashBox>
        </div>
    </div>
    
    </>

}

export default Profile;