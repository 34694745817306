import { useEffect, useState } from "react"
import { PageLoaderImage } from "../Pageloader";

import Modal, { ModalCloser, ModalContent } from '../Modal';
import { useParams } from "react-router-dom";
import { Pagination, PaginationItem } from "./Reports";
import { BsInfoCircle } from "react-icons/bs";
import APIMan from "../../data/APIMan";
import Tooltip from "../Tooltip";

const Goals = (props) => {

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState("");

    const [list, setList] = useState([]);
    const [sites, setSites] = useState([]);

    const { siteID } = useParams();

    const [modalOpen, setModalOpen] = useState(false);
    const [modalLoading, setModalLoading] = useState(false);
    const [modalError, setModalError] = useState("");
    const [modalType, setModalType] = useState('note');
    const [mNoteTitle, setMNoteTitle] = useState("");
    const [mNoteText, setMNoteText] = useState("");
    const [mKeyword, setMKeyword] = useState("");
    const [mKeywordPos, setMKeywordPos] = useState(1);
    const [mVisitors, setMVisitors] = useState(10);
    const [mConversions, setMConversions] = useState(10);
    const [newDay, setNewDay] = useState('*');
    const [newMonth, setNewMonth] = useState('*');
    const [newWeekday, setNewWeekday] = useState('*');

    const [startTime, setStartTime] = useState("");
    const [endTime, setEndTime] = useState("");
 
    useEffect(() => {
        var startDate = new Date();
        startDate.setDate(startDate.getDate() - 30);
        var startDateString = startDate.getUTCFullYear() + "-" + zeronumber(startDate.getUTCMonth()+1) 
        + "-" + zeronumber(startDate.getUTCDate());
        setStartTime(startDateString);
        var endDate = new Date();
        var endDateString = endDate.getUTCFullYear() + "-" + zeronumber(endDate.getUTCMonth()+1) 
        + "-" + zeronumber(endDate.getUTCDate());
        setEndTime(endDateString);
        reloadList(startDateString, endDateString);
    }, []);

    const zeronumber = (input) => {
        return (
            input > 9 ? input : "0" + input
        );
    }

    const updateStartTime = (e) => {
        setStartTime(e.currentTarget.value);
        reloadList(e.currentTarget.value, endTime);
    }

    const updateEndTime = (e) => {
        setEndTime(e.currentTarget.value);
        reloadList(startTime, e.currentTarget.value);
    }

    const reloadList = (start = startTime, end = endTime) => {
        setLoading(true);
        var token = localStorage.getItem('auth_token') || 'unset';
        var site = localStorage.getItem('csid') || 'unset';
        APIMan.sendRequest('getgoals.php?auth_token=' + token + '&site_token=' + site +
        '&start=' + start + '&end=' + end, result => {
            if(result.error) {
                setError(result.errorMessage);
            }else {
                setError('');
                setList(result.data.goals);
                setSites(result.data.sites);
            }
            setLoading(false);
        });
    }

    const selectChangeEvent = (event, callback) => {
        callback((event.target).querySelector('option:checked[value]').value);
    }

    const createGoal = () => {
        setModalLoading(true);
        var data = {};
        if(modalType === 'note') {
            data = {
                title: mNoteTitle,
                text: mNoteText
            };
        }else if(modalType === 'keyword') {
            data = {
                keyword: mKeyword,
                place_want: mKeywordPos
            };
        }else if(modalType === 'visitors') {
            data = {
                count: mVisitors,
                day: newDay,
                month: newMonth,
                weekday: newWeekday
            };
        }else if(modalType === 'conversions') {
            data = {
                count: mConversions,
                day: newDay,
                month: newMonth,
                weekday: newWeekday
            };
        }
        data = JSON.stringify(data);
        var token = localStorage.getItem('auth_token') || 'unset';
        var site = localStorage.getItem('csid') || 'unset';
        APIMan.sendRequest('creategoal.php?auth_token=' + token + '&site_token=' + site + 
        '&type=' + modalType + '&data=' + data, result => {
            if(result.error) {
                setModalError(result.errorMessage);
            }else {
                setModalError('');
                reloadList();
                setModalOpen(false);
                setNewDay('*'); setNewMonth('*'); setNewWeekday('*');
                setModalType('note'); setMKeyword(''); setMKeywordPos(1);
                setMNoteTitle(''); setMNoteText(''); setMConversions(0);
            }
            setModalLoading(false);
        });
    }

    const markAsDone = (goal) => {
        setLoading(true);
        var token = localStorage.getItem('auth_token') || 'unset';
        var site = localStorage.getItem('csid') || 'unset';
        APIMan.sendRequest('updategoal.php?auth_token=' + token + '&site_token=' + site + 
        '&goal=' + goal + '&percentage=100', result => {
            if(result.error) {
                setError(result.errorMessage);
                setLoading(false);
            }else {
                reloadList();
            }
        });
    }

    const deleteGoal = (goal) => {
        setLoading(true);
        var token = localStorage.getItem('auth_token') || 'unset';
        var site = localStorage.getItem('csid') || 'unset';
        APIMan.sendRequest('deletegoal.php?auth_token=' + token + '&site_token=' + site + 
        '&goal=' + goal, result => {
            if(result.error) {
                setError(result.errorMessage);
                setLoading(false);
            }else {
                reloadList();
            }
        });
    }

    if(loading) return <PageLoaderImage />

    if(error !== "") return <div className="alert error">{error}</div>

    return <>

    <div className="flex flex-between">
        <div><h1>Ziele</h1></div>
        <div><button className="btn add" onClick={() => setModalOpen(true)}>Ziel erstellen</button></div>
    </div>

    <div className="flex flex-between flex-inline flex-spacing">
        <div className="formfield">
            <input type="date" id="start" defaultValue={startTime} 
                    onChange={updateStartTime} />
            <label htmlFor="start">Zeige Einträge von</label>
        </div>
        <div className="formfield">
            <input type="date" id="end" defaultValue={endTime} 
                    onChange={updateEndTime} />
            <label htmlFor="end">bis</label>
        </div>
    </div>

    {list.length === 0 && <p>In dieser Zeitspanne wurden keine Ziele definiert.</p>}

    {list.map(item => {
        return <div className={"goalitem" + (item.progress === "100" ? " done" : "")} key={item.id}>
            <h2>{item.data.data.title}</h2>
            {item.data.type === 'note' && <p className="note-text">{item.data.data.text}</p>}
            {item.data.type === 'visitors' && <p className="note-text">
                Erreiche {item.data.data.count} Besucher an einem Tag.
                {(item.data.data.date.day !== '*' || item.data.data.date.month !== '*' ||
                item.data.data.date.weekday !== '*') && <Tooltip content={<>
                    Tag: {item.data.data.date.day}<br />
                    Monat: {item.data.data.date.month}<br />
                    Wochentag: {item.data.data.date.weekday}<br />
                </>}>
                    &nbsp;<BsInfoCircle />    
                </Tooltip>}
            </p>}
            {item.data.type === 'conversions' && <p className="note-text">
                Erreiche {item.data.data.count} Conversions an einem Tag.
                {(item.data.data.date.day !== '*' || item.data.data.date.month !== '*' ||
                item.data.data.date.weekday !== '*') && <Tooltip content={<>
                    Tag: {item.data.data.date.day}<br />
                    Monat: {item.data.data.date.month}<br />
                    Wochentag: {item.data.data.date.weekday}<br />
                </>}>
                    &nbsp;<BsInfoCircle />    
                </Tooltip>}
            </p>}
            <div className="progress">
                <div className="label-0">{item.progress}%</div>
                <div className="bar-holder">
                    <div className="bar" style={{width: item.progress + "%"}}></div>
                </div>
                <div className="label-100">100%</div>
            </div>
            <p className="note-text">
                <small>
                    Erstellt am {item.created}
                </small>
            </p>
            <div className="markasdone">
                {item.progress !== "100" && 
                    <div onClick={() => markAsDone(item.id)}>Als erledigt markieren</div> }
                <div onClick={() => deleteGoal(item.id)}>Entfernen</div>
            </div>
        </div>
    })}
    
    <Modal open={modalOpen} loading={modalLoading} width={500}>
        <ModalCloser onClick={() => setModalOpen(false)} />
        <ModalContent>
            <h1>Neues Ziel erstellen</h1>
            {modalError !== "" && <div className="alert error">{modalError}</div>}
            <p>
                Hier können Ziele erstellt werden, die, je nach Typ, automatisch als erledtigt 
                markiert werden, sobald sie erreicht sind.
            </p>
            <div className="formfield">
                <select id="type" onChange={e => selectChangeEvent(e, setModalType)}>
                    <option value="note" selected>Custom</option>
                    <option value="keyword">Keyword</option>
                    <option value="visitors">Besucher</option>
                    <option value="conversions">Conversions</option>
                </select>
            </div>
            <br />

            {modalType === 'note' && <>
                <p>
                    <b>Eigenes Ziel:</b> Hier können Sie Titel und Inhalt frei wählen.
                </p>
                <p><BsInfoCircle /> Dieses Ziel kann nicht automatisch überprüft werden.</p>
                <div className="formfield">
                    <input type="text" id="title" value={mNoteTitle}
                        onChange={e => setMNoteTitle(e.currentTarget.value)} />
                    <label htmlFor="title">Titel</label>
                </div>
                <div className="formfield">
                    <input type="text" id="text" value={mNoteText}
                        onChange={e => setMNoteText(e.currentTarget.value)} />
                    <label htmlFor="text">Text</label>
                </div>
            </>}

            {modalType === 'keyword' && <>
                <p>
                    <b>Keyword:</b> Geben Sie ein Keyword und einen Platz ein und das Ziel gilt als
                    erfüllt, sobald das Keyword den angebenen Platz oder höher erreicht hat.<br />
                </p>
                <p><BsInfoCircle /> Dieses Ziel kann nicht automatisch überprüft werden.</p>
                <div className="formfield">
                    <input type="text" id="keyword" value={mKeyword}
                        onChange={e => setMKeyword(e.currentTarget.value.toLowerCase())} />
                    <label htmlFor="keyword">Keyword</label>
                </div>
                <div className="formfield">
                    <input type="number" id="keywordpos" value={mKeywordPos}
                        onChange={e => setMKeywordPos(e.currentTarget.value)} />
                    <label htmlFor="keywordpos">Gewünschte Position</label>
                </div>
            </>}

            {modalType === 'visitors' && <>
                <p>
                    <b>Besucher:</b> Das Ziel gilt als erfüllt, wenn die angegebene Besucherzahl erreicht wird. 
                    Sie können auch nur bestimmte Tage in die Überprüfung einschließen.
                </p>
                <div className="formfield">
                    <input type="number" id="visitors" value={mVisitors}
                        onChange={e => setMVisitors(e.currentTarget.value)} />
                    <label htmlFor="visitors">Gewünschte Besucherzahl</label>
                </div>
            </>}

            {modalType === 'conversions' && <>
                <p>
                    <b>Conversions:</b> Das Ziel gilt als erfüllt, wenn die angegebenen Conversions erreicht werden. 
                    Sie können auch nur bestimmte Tage in die Überprüfung einschließen.
                </p>
                <div className="formfield">
                    <input type="number" id="conversions" value={mConversions}
                        onChange={e => setMConversions(e.currentTarget.value)} />
                    <label htmlFor="conversions">Gewünschte Conversions</label>
                </div>
            </>}

            {(modalType === 'visitors' || modalType === 'conversions') && <>
            <div className="flex flex-inline flex-between flex-spacing">
                <div className="formfield">
                    <select onChange={(e) => selectChangeEvent(e, setNewDay)} id="day">
                        <option value="*">Jeden Tag</option>
                        <option value="1">01</option>
                        <option value="2">02</option>
                        <option value="3">03</option>
                        <option value="4">04</option>
                        <option value="5">05</option>
                        <option value="6">06</option>
                        <option value="7">07</option>
                        <option value="8">08</option>
                        <option value="9">09</option>
                        <option value="10">10</option>
                        <option value="11">11</option>
                        <option value="12">12</option>
                        <option value="13">13</option>
                        <option value="14">14</option>
                        <option value="15">15</option>
                        <option value="16">16</option>
                        <option value="17">17</option>
                        <option value="18">18</option>
                        <option value="19">19</option>
                        <option value="20">20</option>
                        <option value="21">21</option>
                        <option value="22">22</option>
                        <option value="23">23</option>
                        <option value="24">24</option>
                        <option value="25">25</option>
                        <option value="26">26</option>
                        <option value="27">27</option>
                        <option value="28">28</option>
                        <option value="29">29</option>
                        <option value="30">30</option>
                        <option value="31">31</option>
                    </select>
                    <label htmlFor="day">Tag im Monat</label>
                </div>

                <div className="formfield">
                    <select onChange={(e) => selectChangeEvent(e, setNewMonth)} id="month">
                        <option value="*">Jeder Monat</option>
                        <option value="1">Januar</option>
                        <option value="2">Februar</option>
                        <option value="3">März</option>
                        <option value="4">April</option>
                        <option value="5">Mai</option>
                        <option value="6">Juni</option>
                        <option value="7">Juli</option>
                        <option value="8">August</option>
                        <option value="9">September</option>
                        <option value="10">Oktober</option>
                        <option value="11">November</option>
                        <option value="12">Dezember</option>
                    </select>
                    <label htmlFor="month">Monat</label>
                </div>

                <div className="formfield">
                    <select onChange={(e) => selectChangeEvent(e, setNewWeekday)} id="weekday">
                        <option value="*">Jeden Wochentag</option>
                        <option value="1">Montags</option>
                        <option value="2">Dienstags</option>
                        <option value="3">Mittwochs</option>
                        <option value="4">Donnerstags</option>
                        <option value="5">Freitags</option>
                        <option value="6">Samstags</option>
                        <option value="7">Sonntags</option>
                    </select>
                    <label htmlFor="weekday">Wochentag</label>
                </div>

            </div>
            </>}

            <br />
            <button className="btn" onClick={createGoal}>
                Ziel erstellen
            </button>
        </ModalContent>
    </Modal>

    <Pagination>
        {sites.map(site => {
            return <PaginationItem 
                to={'/home/goals/site/' + site}
                active={site === parseInt(siteID)}>
                {site}
            </PaginationItem>
        })}
    </Pagination>

    </>

}

export default Goals;