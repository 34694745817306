import { useState } from "react"
import { NavLink } from "react-router-dom"

const Sidebar = (props) => {

    return <>
    
        <div className="sidebar">
            {props.children}
        </div>
    
    </>

}
export default Sidebar;

export function SidebarItem(props) {

    return <>
    
    <div className="sidebaritem">
        <NavLink to={props.to} activeClassName={'active'} exact={props.exact}>
            <div className="icon">{props.icon}</div>
            <span>{props.children}</span>
        </NavLink>
    </div>
    
    </>

}