import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import APIMan from "../../data/APIMan";
import { DashBox } from "../home_pages/Home";
import { PageLoaderImage } from "../Pageloader";

const User = (props) => {

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState("");
    const { userID } = useParams();
    const [saveError, setSaveError] = useState("");

    const [userName, setUserNameFinal] = useState("");
    const setUserName = (input) => { setUserNameFinal(input.toLowerCase()); };
    const [userDisplayName, setUserDisplayName] = useState("");
    // const [userImage, setUserImage] = useState(null);
    const [userMail, setUserMail] = useState("");
    
    const [securetoken, setSecuretoken] = useState("");

    const [pagePermissions, setPagePermissions] = useState([]);
    const [globalPermissions, setGlobalPermissions] = useState();

    const [pageList, setPageList] = useState([]);
    const [selectedPage, setSelectedPage] = useState("none");

    const history = useHistory();

    const selectChangeEvent = (event, callback) => {
        callback((event.target).querySelector('option:checked[value]').value);
    }

    const changeGlobalPermissions = (event, name) => {
        setGlobalPermissions((globalPermissions) => ({
            ...globalPermissions,
            [name]: event.target.checked
        }));
    }

    const changeSitePermissions = (event, name) => {
        setPagePermissions((pagePermissions) => ({
            ...pagePermissions,
            [selectedPage]: {
                ...pagePermissions[selectedPage],
                [name]: event.target.checked
            }
        }));
    }
    useEffect(() => {
        updateView();
    }, [props, userID]);

    const updateView = () => {
        setLoading(true);
        setError('');
        setPageList([]);
        var token = localStorage.getItem('auth_token') || "unset";
        APIMan.sendRequest('getuser.php?auth_token=' + token + '&user=' + userID, result => {
            if(result.error) {
                setError(result.errorMessage);
            }else {
                setError('');
                setUserName(result.data.user.username);
                setUserDisplayName(result.data.user.name);
                setUserMail(result.data.user.mail);
                // setUserImage(result.data.user.image);
                if(result.data.user.permissions.actions !== undefined)
                    setGlobalPermissions(result.data.user.permissions.actions);
                if(result.data.user.permissions.sites !== undefined)
                    setPagePermissions(result.data.user.permissions.sites);
                setPageList(result.data.sites);
                setSelectedPage(result.data.sites[0].id);
                setSecuretoken(result.data.user.securetoken);
            }
            setLoading(false);
        });
    };

    const removePage = () => {
        delete pagePermissions[selectedPage];
        setPagePermissions(Object.assign({}, pagePermissions));
    }
    
    const addPage = () => {
        setPagePermissions({...pagePermissions, [selectedPage]: {}});
    }

    const save = () => {
        setSaveError("");
        setLoading(true);
        var token = localStorage.getItem('auth_token') || "unset";
        var permissions = {
            actions: globalPermissions,
            sites: pagePermissions,
        };
        permissions = encodeURI(JSON.stringify(permissions));
        APIMan.sendRequest('saveuser.php?auth_token=' + token
        + '&user=' + userID + '&permissions=' + permissions + '&username=' + userName + '&name=' + 
        userDisplayName + '&mail=' + userMail, result => {
            if(result.error) {
                setSaveError(result.errorMessage);
            }else {
                setSaveError('');
            }
            setLoading(false);
        });
    }

    const loginForUser = () => {
        localStorage.setItem('auth_token', securetoken);
        localStorage.setItem('csid', '');
        window.location.href = "/";
    }

    const deleteUser = () => {
        setLoading(true);
        var token = localStorage.getItem('auth_token') || "unset";
        if(token !== securetoken){
            APIMan.sendRequest('deleteuser.php?auth_token=' + token + '&user=' + userID, result => {
                if(result.error) {
                    setError(result.errorMessage);
                    setLoading(false);
                }else {
                    history.push('/profile/users');
                }
            });
        }
    }

    if(loading) {
        return <PageLoaderImage />
    }

    if(error !== "") {
        return <>
            <div className="alert error">{error}</div>
        </>
    }

    return <>

        <div className="flex flex-between">
            <div>
                <h1>Benutzer&shy;verwaltung</h1>
            </div>
            <div>
                <button className="btn simple" onClick={save}>
                    Speichern
                </button>
            </div>
        </div>

        {saveError !== "" && 
            <div className="alert error">{saveError}</div>
        }

        <div className="flex-grid">

            <div className="col">
                <DashBox>
                    <h2>Anzeige</h2>

                    <div className="formfield">
                        <input type="text" id="displayname" autoComplete="off"
                            value={userDisplayName} onChange={e => setUserDisplayName(e.currentTarget.value)} required />
                        <label htmlFor="displayname">Anzeigename</label>
                    </div>
                </DashBox>
            </div>

            <div className="col">
                <DashBox>
                    <h2>Daten</h2>

                    <div className="formfield">
                        <input type="text" id="username" autoComplete="off"
                            value={userName} onChange={e => setUserName(e.currentTarget.value)} required />
                        <label htmlFor="username">Benutzername</label>
                    </div>

                    <div className="formfield">
                        <input type="email" id="usermail" autoComplete="off"
                            value={userMail} onChange={e => setUserMail(e.currentTarget.value)} required />
                        <label htmlFor="usermail">E-Mail Adresse</label>
                    </div>
                </DashBox>
            </div>

        </div>

        <div className="flex-grid">

            <div className="col">
                <DashBox>
                    <h2>Berechtigungen</h2>
                    <div className="flex-grid">
                        <div className="col">
                            <h3>Benutzer</h3>
                            
                            <GlobalPermField id="gp_login" name="login" val={globalPermissions} 
                                setVal={changeGlobalPermissions} label="Anmelden" />

                            <GlobalPermField name="changepassword" val={globalPermissions} 
                                setVal={changeGlobalPermissions} label="Passwort ändern" />

                            <GlobalPermField name="changeimage" val={globalPermissions} 
                                setVal={changeGlobalPermissions} label="Profilbild ändern" />

                            <GlobalPermField name="manageusers" val={globalPermissions} 
                                setVal={changeGlobalPermissions} label="Benutzerverwaltung" />

                            <GlobalPermField name="managesites" val={globalPermissions} 
                                setVal={changeGlobalPermissions} label="Seitenverwaltung" />

                        </div>
                        <div className="col">
                            <h3>Seiten</h3>

                            <div className="formfield">
                                <select onChange={e => selectChangeEvent(e, setSelectedPage)} id="sites">
                                    {pageList.map(page => {
                                        if(page.id in pagePermissions) {
                                            return <option key={page.id} value={page.id}>* {page.name}</option>
                                        }else {
                                            return <option key={page.id} value={page.id}>{page.name}</option>
                                        }
                                    })} 
                                </select>
                                <label htmlFor="sites">Seiten-Berechtigungen</label>
                            </div>

                            {
                                selectedPage in pagePermissions ? (<>

                                    <div className="formfield underline">
                                        <input type="checkbox" onClick={removePage} checked id="removepage" readOnly />
                                        <label htmlFor="removepage">Hat Zugriff auf diese Seite</label>
                                    </div>

                                    <GlobalPermField name="createreports" val={pagePermissions[selectedPage]} 
                                        setVal={changeSitePermissions} label="Reports erstellen" />

                                    <GlobalPermField name="getreports" val={pagePermissions[selectedPage]} 
                                        setVal={changeSitePermissions} label="Reports ansehen" />

                                    <GlobalPermField name="renamereport" val={pagePermissions[selectedPage]} 
                                        setVal={changeSitePermissions} label="Reports umbenennen" />

                                    <GlobalPermField name="deletereport" val={pagePermissions[selectedPage]} 
                                        setVal={changeSitePermissions} label="Reports löschen" underline />

                                    <GlobalPermField name="createcrons" val={pagePermissions[selectedPage]} 
                                        setVal={changeSitePermissions} label="Aufgaben erstellen" />
                                    
                                    <GlobalPermField name="managecrons" val={pagePermissions[selectedPage]} 
                                        setVal={changeSitePermissions} label="Aufgaben ansehen" />

                                    <GlobalPermField name="deletecrons" val={pagePermissions[selectedPage]} 
                                        setVal={changeSitePermissions} label="Aufgaben löschen" underline />

                                    <GlobalPermField name="createevent" val={pagePermissions[selectedPage]} 
                                        setVal={changeSitePermissions} label="Ereignis eintragen" />

                                    <GlobalPermField name="getevents" val={pagePermissions[selectedPage]} 
                                        setVal={changeSitePermissions} label="Ereignisse ansehen" />

                                    <GlobalPermField name="deleteevent" val={pagePermissions[selectedPage]} 
                                        setVal={changeSitePermissions} label="Ereignis löschen" underline />

                                    <GlobalPermField name="creategoals" val={pagePermissions[selectedPage]}
                                        setVal={changeSitePermissions} label="Ziele erstellen" />

                                    <GlobalPermField name="getgoals" val={pagePermissions[selectedPage]}
                                        setVal={changeSitePermissions} label="Ziele ansehen" />

                                        <GlobalPermField name="editgoals" val={pagePermissions[selectedPage]}
                                            setVal={changeSitePermissions} label="Ziele bearbeiten" />

                                    <GlobalPermField name="deletegoals" val={pagePermissions[selectedPage]}
                                        setVal={changeSitePermissions} label="Ziele löschen" underline /> 

                                </>) : (<>
                                    <div className="formfield underline">
                                        <input type="checkbox" onClick={addPage} id="addpage" checked={false} readOnly />
                                        <label htmlFor="addpage">Hat Zugriff auf diese Seite</label>
                                    </div>
                                </>)
                            }


                        </div>
                    </div>
                </DashBox>
            </div>

        </div>

        <br />

        <h2>Weitere Aktionen</h2>

        <DashBox>
            <p>
                Um das Passwort eines Benutzers zu ändern oder die Seite aus Sicht des Nutzers  zu
                betrachten, besteht für Administratoren die Möglichkeit, sich als andere Nutzer ohne 
                Passworteingabe anzumelden. Mit dem Anmelden als ein anderer Nutzer wird der aktuelle
                Account abgemeldet. Um zum Administrator-Account zurückzukehren, ist eine Abmeldung und 
                erneute Anmeldung mit dem Administrator-Account erforderlich.
            </p>

            <button className="btn simple" onClick={loginForUser}>Als {userDisplayName} anmelden</button>
        </DashBox>
        {securetoken !== localStorage.getItem('auth_token') &&
        <DashBox>
            <p>
                <b>ACHTUNG:</b> Der Benutzer wird sofort vollständig gelöscht! Diese Aktion kann nicht 
                Rückgängig gemacht werden! Es ist keine weitere Bestätigung zum löschen erforderich.
            </p>

            <button className="btn simple" onClick={deleteUser}>Benutzer löschen</button>
        </DashBox>}

    </>

}

function GlobalPermField(props) {

    return <div className={"formfield" + (props.underline ? ' underline' : '')}>
        <input type="checkbox" checked={props.val[props.name] || false} 
        onChange={e => props.setVal(e, props.name)} 
        id={"gp_" + props.name} />
        <label htmlFor={"gp_" + props.name}>{props.label}</label>
    </div>

}

function SitePermField(props) {

    return <div className={"formfield" + (props.underline ? ' underline' : '')}>
        <input type="checkbox" checked={props.val[props.name] || false} 
        onChange={e => props.setVal(e, props.name)} 
        id={"sp_" + props.name} />
        <label htmlFor={"sp_" + props.name}>{props.label}</label>
    </div>

}

export default User;