import APIMan from "../../data/APIMan";

const API = (props) => {

    return <>
    <div className="docs">
    
    <h1>Krauss Monitoring RESTful API</h1>

    <ul>
        <li><a href="#authentifizierung">Authentifizierung</a></li>
        <li><a href="#aufbau-einer-anfrage">Aufbau einer Anfrage</a></li>
        <li><a href="#aufbau-eines-ergebnisses">Aufbau eines Ergebnisses</a></li>
        <li><a href="#fehlerbehandlung">Fehlerbehandlung</a></li>
        <li><a href="#api-endpoints">API Endpoints</a></li>
    </ul>

    <hr />

    <p>
        Die API befindet sich auf <code>{APIMan.apiurl}</code>.
    </p>
    <div className="alert success">
        Aus Sicherheitsgründen ist nur eine <b>verschlüsselte Verbindung</b> über 
        das HTTPS-Protokoll zugelassen.
    </div>

    <hr />

    <h2 id="authentifizierung">Authentifizierung</h2>

    <p>
        In der Regel benötigen alle API-Anfragen eine Authentifizierung. Eine Ausnahme ist hier
        der <code>/login.php</code>-Endpoint, hier werden lediglich die Parameter <code>
        username</code> und <code>password</code> benötigt.
    </p>
    <p>
        Bei der Anmeldung über Benutzername und Passwort an die API wird ein Authentifizierungstoken
        zurückgegeben.
    </p>
    <code className="block">
        // Anmeldung an das System mit Benutzername und Passwort<br />
        {APIMan.apiurl}login.php?username=admin&password=pwd
    </code>
    <p>
        Für diese Anfrage sieht das Ergebnis, wenn die Zugangsdaten stimmen, so aus:
    </p>
    <code className="block">
    <pre>
        {JSON.stringify({
        "meta": {
            "code": "200",
            "message": "success"
        },
        "data": {
            "auth_token": "xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx"
        }
        }, undefined, 2)}
    </pre>
    </code>
    <p>
        Falls die eingegebenen Daten nicht stimmen, sieht das Ergebnis so aus:
    </p>
    <code className="block">
    <pre>
        {JSON.stringify({
        "meta": {
            "code": 403,
            "message": "credentials_invalid",
            "output": "Die Anmeldedaten sind ungültig."
        }
        }, undefined, 2)}
    </pre>
    </code>
    <p>
        Mehr dazu in der <a href="#fehlerbehandlung">Fehlerbehandlung</a>.
    </p>

    <hr />

    <h2 id="aufbau-einer-anfrage">Aufbau einer Anfrage</h2>
    
    <p>
        Die HTTP-Methode der Anfragen an die API ist <b>immer</b> GET.<br />
        Alle benötigten Daten werden über GET-Parameter an die API übermittelt.
    </p>
    <p>
        Der Aufbau der URL lautet also:
    </p>
    <code className="block">
        // API Server - Endpoint - Parameter<br />
        https://api.monitoring.krauss-webstats.de/endpoint.php?parameters=here
    </code>

    <hr />

    <h2 id="aufbau-eines-ergebnisses">Aufbau eines Ergebnisses</h2>
    <p>
        Die API gibt das Ergebnis immer im JSON-Format zurück.
    </p>
    <code className="block">
    <pre>
        {JSON.stringify({
        "meta": {
            "code": 200,
            "message": "success",
        },
        "data": {}
        }, undefined, 2)}
    </pre>
    </code>
    <p>
        Das eigentliche Ergebnis befindet sich in <code>data</code>. In <code>meta</code> sind 
        zusätzliche Informationen zur Anfrage enthalten, beispielsweise der Statuscode und die 
        Nachricht des Servers.
    </p>

    <hr />
    <h2 id="fehlerbehandlung">Fehlerbehandlung</h2>
    <p>
        Wenn der Statuscode nicht 200 ist, ist die Anfrage fehlgeschlagen.
    </p>
    <div className="table">
        <table>
            <thead>
                <tr>
                    <td>Statuscode</td>
                    <td>Beschreibung</td>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>200</td>
                    <td>Die Anfrage war erfolgreich.</td>
                </tr>
                <tr>
                    <td>400 <i>Bad Request</i></td>
                    <td>Die Anfrage enthält Fehler, bspw. fehlende oder ungültige Parameter.</td>
                </tr>
                <tr>
                    <td>403 <i>Forbidden</i></td>
                    <td>Die Authentifizierung ist fehlgeschlagen, kein Zugriff erlaubt.</td>
                </tr>
                <tr>
                    <td>404 <i>Not found</i></td>
                    <td>Der angeforderte API-Endpoint wurde nicht gefunden.</td>
                </tr>
                <tr>
                    <td>500 <i>Internal Server Error</i></td>
                    <td>Der API-Server funktioniert nicht.</td>
                </tr>
            </tbody>
        </table>
    </div>

    <p>
        Zur einfachen Ausgabe des Fehlers an den Nutzer, sendet der Server eine Fehlermeldung in 
        deutsch mit:
    </p>
    <code className="block">
    <pre>
        {JSON.stringify({
        "meta": {
            "code": 403,
            "message": "credentials_invalid",
            "output": "Die Anmeldedaten sind ungültig."
        }
        }, undefined, 2)}
    </pre>
    </code>
    <p>
        Die Fehlermeldung befindet sich in <code>meta -&gt; output</code>.
    </p>

    <hr />
    <h2 id="api-endpoints">API Endpoints</h2>

    <ul>
        <li><a href="#endpoints-user">Benutzer</a></li>
        <li><a href="#endpoints-sites">Seiten</a></li>
        <li><a href="#endpoints-reports">Reports</a></li>
        <li><a href="#endpoints-goals">Ziele</a></li>
        <li><a href="#endpoints-events">Ereignisse</a></li>
        <li><a href="#endpoints-cronjobs">Cronjobs</a></li>
    </ul>

    <h2 id="endpoints-user">Endpoints: Benutzer</h2>

    <h4>Anmeldung</h4>
    <code className="block">/login.php?username=USERNAME&password=PASSWORD</code>
    <p>
        Ergebnis:
    </p>
    <ul>
        <li>
            <code>auth_token</code> Authentifizierungstoken für den Benutzer - wird bei weiteren 
            Anfragen benötigt
        </li>
    </ul>

    <br />
    <h2 id="endpoints-sites">Endpoints: Seiten</h2>

    <h4>Liste aller Seiten</h4>
    <code className="block">/getsites.php?auth_token=AUTH_TOKEN</code>
    <p>
        Ergebnis:
    </p>
    <ul>
        <li>
            <code>Array</code> Alle Seiten, auf die der Benutzer Zugriff hat
            <ul>
                <li><code>id</code> Seiten-ID</li>
                <li><code>name</code> Seiten-Name</li>
                <li><code>url</code> Seiten-URL</li>
            </ul>
        </li>
    </ul>
    

    <br />
    <h2 id="endpoints-reports">Endpoints: Reports</h2>

    <h4>Liste aller Reports</h4>
    <code className="block">
        /getreports.php?auth_token=AUTH_TOKEN&site_token=SITE_ID
        [&amount=20][&offset=40]
    </code>
    <p>
        Ergebnis:
    </p>
    <ul>
        <li>
            <code>reports</code> Array aller Reports
            <ul>
                <li><code>id</code> Report-ID</li>
                <li><code>token</code> Report-Token</li>
                <li><code>name</code> Report-Name</li>
                <li><code>start</code> Startdatum (d.m.Y)</li>
                <li><code>end</code> Enddatum (d.m.Y)</li>
                <li><code>duration</code> Dauer in Tagen mit Einheit</li>
                <li><code>type</code> Report-Typ (0 = wird erstellt, 1 = automatisch, 2 = manuell)</li>
            </ul>
        </li>
        <li>
            <code>sites</code> Array für die Seitenauswahl (Frontend)
            <ul>
                <li>Integer</li>
            </ul>
        </li>
    </ul>

    <br />
    <h4>Einzelner Report</h4>
    <code className="block">
        /getreport.php?auth_token=AUTH_TOKEN&site_token=SITE_ID&report=REPORT_TOKEN
    </code>
    <p>
        Ergebnis:
    </p>
    <ul>
        <li><code>id</code> Report-ID</li>
        <li><code>name</code> Report-Name</li>
        <li><code>start</code> Startdatum (d.m.Y)</li>
        <li><code>end</code> Enddatum (d.m.Y)</li>
        <li><code>duration</code> Dauer in Tagen mit Einheit</li>
        <li><code>type</code> Report-Typ (0 = wird erstellt, 1 = automatisch, 2 = manuell)</li>
        <li>
            <code>content</code>
            <ul>
                <li>
                    <code>sites</code>
                    <ul>
                        <li>
                            <code>days</code>
                            <ul>
                                <li>
                                    <code>[YYYY-MM-DD]</code> Alle Tage im Zeitraum
                                    <ul>
                                        <li>
                                            <code>visitors</code>
                                            <ul>
                                                <li><code>total</code> Gesamtbesucher</li>
                                                <li><code>new</code> Neue Besucher</li>
                                                <li><code>views</code> Seitenaufrufe</li>
                                            </ul>
                                        </li>
                                        <li>
                                            <code>referrers</code>
                                            <ul>
                                                <li><code>searchengines</code> Besucher von Suchmaschinen</li>
                                                <li><code>socialmedia</code> Besucher von Sozialen Medien</li>
                                                <li><code>direct</code> Direktaufrufe</li>
                                                <li><code>websites</code> Besucher von anderen Websites</li>
                                                <li><code>campaigns</code> Besucher von Werbekampagnen</li>
                                            </ul>
                                        </li>
                                        <li>
                                            <code>conversions</code>
                                            <ul>
                                                <li><code>total</code> Gesamtconversions</li>
                                                <li><code>rate</code> Conversion-Rate in Prozent</li>
                                            </ul>
                                        </li>
                                        <li>
                                            <code>avgtimes</code>
                                            <ul>
                                                <li><code>visit</code> Durchsch. Aufenthaltszeit</li>
                                                <li><code>loading</code> Durchsch. Ladezeit</li>
                                            </ul>
                                        </li>
                                        <li>
                                            <code>times</code>
                                            <ul>
                                                <li>
                                                    <code>["00" - "23"]</code> Einzelne Stunden des Tages
                                                    <ul>
                                                        <li><code>visitors</code> Besucher</li>
                                                        <li><code>actions</code> Aktionen</li>
                                                    </ul>
                                                </li>
                                            </ul>
                                        </li>
                                        <li>
                                            <code>devices</code>
                                            <ul>
                                                <li>
                                                    <code>[Device Name]</code> Einzelne Geräte
                                                    <ul>
                                                        <li><code>visitors</code> Besucher von diesem Gerät</li>
                                                    </ul>
                                                </li>
                                            </ul>
                                        </li>
                                        <li><code>actions</code> Gesamtaktionen des Tages</li>
                                        <li><code>bouncerate</code> Absprungrate</li>
                                    </ul>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <code>sites</code> Einzelne Seiten
                            <ul>
                                <li>
                                    <code>list</code> Liste aller Seiten mit Daten
                                    <ul>
                                        <li>
                                            <code>[path-to-site]</code> Relative Seiten-URL
                                            <ul>
                                                <li><code>visitors</code> Besucher auf der Seite</li>
                                                <li><code>grown</code> Wachstum der Seite</li>
                                                <li><code>bouncerate</code> Absprungrate</li>
                                                <li><code>generationtime</code> Ladezeit</li>
                                                <li><code>avgtime</code> Aufenthaltszeit (Durchschn.)</li>
                                            </ul>
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <code>visitors</code> Top-Seiten nach Besuchern
                                    <ul>
                                        <li>String</li>
                                    </ul>
                                </li>
                                <li>
                                    <code>grown</code> Top-Seiten nach Wachstum
                                    <ul>
                                        <li>String</li>
                                    </ul>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <code>total</code> Daten über den gesamten Zeitraum
                            <ul>
                                <li><code>visitors</code> Besucher gesamt</li>
                                <li><code>newvisitors</code> Neue Besucher</li>
                                <li><code>bouncerate</code> Absprungrate</li>
                                <li><code>time</code> Durchschn. Aufenthaltszeit</li>
                                <li><code>loading</code> Durchschn. Ladezeit</li>
                                <li>
                                    <code>actions</code>
                                    <ul>
                                        <li><code>total</code> Aktionen gesamt</li>
                                        <li><code>average</code> Aktionen Durchschn.</li>
                                    </ul>
                                </li>
                                <li>
                                    <code>referrers</code>
                                    <ul>
                                        <li><code>searchengines</code> Besucher von Suchmaschinen</li>
                                        <li><code>socialmedia</code> Besucher von Sozialen Medien</li>
                                        <li><code>direct</code> Direktaufrufe</li>
                                        <li><code>websites</code> Besucher von anderen Websites</li>
                                        <li><code>campaigns</code> Besucher von Werbekampagnen</li>
                                    </ul>
                                </li>
                                <li><code>views</code> Aufrufe</li>
                                <li>
                                    <code>days</code> Seitenaufrufe pro Tag (Array)
                                </li>
                            </ul>
                        </li>
                        <li>
                            <code>conversions</code>
                            <ul>
                                <li>
                                    <code>list</code>
                                    <ul>
                                        <li>
                                            <code>[conversion-ID]</code>
                                            <ul>
                                                <li><code>name</code> Definierter Name</li>
                                                <li><code>desc</code> Beschreibung</li>
                                                <li><code>pattern</code> Matomo Pattern</li>
                                                <li><code>conversions</code> Conversions</li>
                                            </ul>
                                        </li>
                                    </ul>
                                </li>
                                <li><code>total</code> Gesamt</li>
                            </ul>
                        </li>
                    </ul>
                </li>
                <li>
                    <code>keywords</code>
                    <ul>
                        <li>
                            <code>list</code> Top 100 Keywords
                            <ul>
                                <li>
                                    <code>[keyword]</code>
                                    <ul>
                                        <li><code>position</code> Position in Google (DE)</li>
                                        <li><code>kraussscore</code> Krauss Score (deprecated)</li>
                                        <li><code>competitordensity</code> Konkurrenzdichte in Prozent</li>
                                        <li><code>cpc</code> Costs per Click</li>
                                        <li><code>sevo</code> Suchvolumen</li>
                                        <li><code>light</code> Keyword-Ampel</li>
                                    </ul>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <code>top</code> Keywords auf Position 1 - 3
                            <ul>
                                <li>String</li>
                            </ul>
                        </li>
                        <li>
                            <code>potenzial</code> Keywords auf Position 4 - 10
                            <ul>
                                <li>String</li>
                            </ul>
                        </li>
                        <li>
                            <code>ideas</code> Keyword-Ideen
                            <ul>
                                <li>String</li>
                            </ul>
                        </li>
                    </ul>
                </li>
            </ul>
        </li>
    </ul>

    <br />

    <div className="alert warning">
        Die API-Dokumentation ist nicht vollständig und wird noch erweitert.
    </div>


    </div>
    </>

}

export default API;