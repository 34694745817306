import { Link } from "react-router-dom"

const Crons = (props) => {

    console.log(window.ePopup);

    return <>
    
        <h1>Automatisierung</h1>

        <p>
            Manche Aufgaben können automatisiert ausgeführt werden, dazu gehören:
        </p>
        <ul>
            <li>Reports</li>
        </ul>
        <p>
            Die Steuerung der automatisierten Aufgaben findet im Dashboard 
            unter <Link to="/home/schedule">Automatisierung</Link> statt. Um neue Aufgaben hinzuzufügen,
            kann über den Button oben rechts das entsprechende Fenster geöffnet werden.
        </p>

        <br />

        <h2>Aufgabe erstellen</h2>

        <p>
            Jede Aufgabe besteht aus 
        </p>
        <ul>
            <li>einem Namen,</li>
            <li>einer Regel zur Ausführung und</li>
            <li>einer Aufgabe.</li>
        </ul>
        <br />

        <p>
            Der Name dient zur Übersichtlichkeit in der Tabelle und hat keine weitere Funktion.
        </p>
        <br />

        <p>
            Die Regel zur Ausführung enthält Informationen darüber, wann die Aufgabe ausgeführt werden
            soll. Alle Aufgaben wiederholen sich, es wird also nicht ein bestimmter Zeitpunkt angegeben,
            sondern eine Regel wie "jeden Freitag" oder "am ersten Tag jedes Jahres".
        </p>
        <div className="alert">
            <b>Hinweis:</b> Krauss Monitoring und die verbunden Dienste funktionieren nach Tagen.
            Daher werden Aufgaben nur einmal am Tag ausgeführt und es ist nicht möglich, Aufgaben 
            zu bestimmten Uhrzeiten ausführen zu lassen.
        </div>
        <p>
            Mehr über die Regeln zur Ausführung befindet sich weiter unten.
        </p>
        <br />

        <p>
            Die Aufgabe ist das, was zu den bestimmten Zeitpunkten passiert. Mögliche Aufgaben:
        </p>
        <div className="table">
            <table>
                <thead>
                    <tr>
                        <td>Aufgabe</td>
                        <td>Beschreibung</td>
                        <td>Aufgabenfelder</td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>create_report</td>
                        <td>
                            Erstellt automatisch einen Report. Das Enddatum ist dabei der jeweils aktuelle Zeitpunkt.
                        </td>
                        <td>
                            <b>Dauer:</b> Dauer des Reports in Tagen
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <p>
            Ohne eine ausgewählte Aufgabe ist das Erstellen nicht möglich.
        </p>
        <br />

        <h2>Regeln zur Ausführung</h2>
        <p>
            Mögliche Eingabefelder:
        </p>
        <ul>
            <li>Tag (01, 02, 03, ..., 29, 30, 31</li>
            <li>Monat (Januar, Februar, ..., November, Dezember)</li>
            <li>Wochentag (Montag, Dienstag, ..., Samstag, Sonntag)</li>
        </ul>
        <br />

        <p>
            Jede Aufgabe wird täglich überprüft und, wenn alle Felder mit dem aktuellen Datum 
            übereinstimmen, ausgeführt. Standardmäßig ist alles auf "Jeden Tag" bzw. "Jeden Monat"
            gesetzt.
        </p>
        <br />

        <p>
            Beispiele:
        </p>
        <div className="table">
            <table>
                <thead>
                    <tr>
                        <td>Regel</td>
                        <td>Tag</td>
                        <td>Monat</td>
                        <td>Wochentag</td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Jeden Montag</td>
                        <td>Jeden Tag</td>
                        <td>Jeden Monat</td>
                        <td>Montags</td>
                    </tr>
                    <tr>
                        <td>Jeden Samstag im Oktober</td>
                        <td>Jeden Tag</td>
                        <td>Oktober</td>
                        <td>Samstags</td>
                    </tr>
                    <tr>
                        <td>Jeden Monat</td>
                        <td>01</td>
                        <td>Jeden Monat</td>
                        <td>Jeden Wochentag</td>
                    </tr>
                    <tr>
                        <td>Jedes Jahr</td>
                        <td>01</td>
                        <td>Januar</td>
                        <td>Jeden Wochentag</td>
                    </tr>
                    <tr>
                        <td>16. Juni</td>
                        <td>16</td>
                        <td>Juni</td>
                        <td>Jeden Wochentag</td>
                    </tr>
                    <tr>
                        <td>Monatsanfang, wenn es ein Montag ist</td>
                        <td>01</td>
                        <td>Jeder Monat</td>
                        <td>Montags</td>
                    </tr>
                </tbody>
            </table>
        </div>
    
    </>

}

export default Crons;