import { useEffect, useState } from "react"
import { Link, NavLink } from "react-router-dom"
import APIMan from "../../data/APIMan";
import UserData from "../../data/UserData";

const NavBar = (props) => {

    const [profileOpen, setProfileOpen] = useState(false);
    const [mobileOpen, setMobileOpen] = useState(false);
    const [sites, setSites] = useState([]);

    useEffect(() => {
        const authtoken = localStorage.getItem('auth_token');
        APIMan.sendRequest('getsites.php?auth_token=' + authtoken, result => {
            if(result.error) {
                setSites([]);
            }else {
                setSites(result.data);
            }
        });
    }, []);

    const switchSite = (id) => {
        localStorage.setItem('csid', id);
        window.location.reload();
    }

    return <>
    
        <nav className={(mobileOpen ? 'open' : '')}>

            <div className="branding">
                <img src="https://www.krausskommunikation.de/wp-content/themes/krauss2020/images/logo.svg" alt="LOGO" />
            </div>

            <div className="content">
                <div className="links">
                    <NavLink to="/home" activeClassName="active"
                        onClick={() => setMobileOpen(false)}>Dashboard</NavLink>
                    <NavLink to="/support" activeClassName="active"
                        onClick={() => setMobileOpen(false)}>Support</NavLink>
                    <NavLink to="/profile" activeClassName="active"
                        onClick={() => setMobileOpen(false)}>Account</NavLink>
                </div>
            </div>

            <div className="profile">
                <div className="profilePicture" 
                    onClick={() => setProfileOpen(!profileOpen)}
                    style={{backgroundImage: 'url(' + UserData.userImage + ')'}}>

                        <div className={"profileContent" + (profileOpen ? " open" : "")}>
                            <div className="header">
                                <span>
                                    <span className="name">{UserData.userNickname}</span>
                                    <span className="mail">{UserData.userMail}</span>
                                </span>
                            </div>
                            <div className="sites">
                                {sites.map(item => (
                                    <div className={"site" + (localStorage.getItem('csid') === item.id ? " active" : "")} 
                                        key={item.id}
                                        onClick={() => switchSite(item.id)}>
                                            {item.name}
                                    </div>  
                                ))}
                            </div>
                            <div className="logout">
                                <Link to="/logout">Abmelden</Link>
                            </div>
                        </div>

                    </div>
            </div>

            <div className="mobile" onClick={() => setMobileOpen(!mobileOpen)}>
                <span></span>
            </div>

        </nav>
    
    </>

}


export function OuterNavigationBar(props) {

    return <>
    
    <nav>

        <div className="branding">
            <img src="https://www.krausskommunikation.de/wp-content/themes/krauss2020/images/logo.svg" alt="LOGO" />
        </div>

    </nav>
    
    </>

}

export default NavBar;