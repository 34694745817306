import { useEffect, useState } from "react";

const Modal = (props) => {

    var style = {};
    if(props.height) style.maxHeight = props.height + "px";
    if(props.width) style.maxWidth = props.width + "px";

    return <>
    
    <div className={"modal" + (props.open ? ' open' : '') + (props.loading ? ' loading' : '')}>
        <div className="modal-window" style={style}>
            {props.children}
        </div>
    </div>

    </>

}
export default Modal;

export function ModalCloser (props) {

    return <>
        <div className="modal-closer" onClick={props.onClick}><span></span></div>
    </>

}

export function ModalContent (props) {
    return <>
        <div className="modal-content">
            {props.children}
        </div>
    </>
}