import { useState } from "react";
import { useHistory } from "react-router-dom";
import APIMan from "../../data/APIMan";

const { DashBox } = require("../home_pages/Home");

function Access(props) {

    const [error, setError] = useState("");

    const [newPW, setNewPW] = useState("");
    const [newPW2, setNewPW2] = useState("");

    const history = useHistory();

    const changePassword = () => {
        if(newPW === newPW2) {
            var token = localStorage.getItem('auth_token') || 'unset';
            APIMan.sendRequest('changepassword.php?auth_token=' + token + '&password=' + encodeURIComponent(newPW), result => {
                if(result.error) {
                    setError(result.errorMessage);
                }else {
                    history.push('/logout');
                }
            });
        }else {
            setError('Die Passwörter stimmen nicht überein.');
        }
    }

    return <>
    
        <DashBox>
            <h2>Passwort</h2>
            {error !== "" && <div className="alert error">{error}</div>}
            <p>Verwenden Sie ein Passwort mit einer mindestlänge von 5 Zeichen.</p>
            <br />
            <div className="formfield">
                <input type="password" id="password" value={newPW} onChange={e => setNewPW(e.currentTarget.value)} />
                <label htmlFor="password">Neues Passwort</label>
            </div>
            <div className="formfield">
                <input type="password" id="password" value={newPW2} onChange={e => setNewPW2(e.currentTarget.value)} />
                <label htmlFor="password">Passwort wiederholen</label>
            </div>
            <button className="btn" onClick={changePassword}>Passwort ändern</button>
        </DashBox>

    </>

}

export default Access;