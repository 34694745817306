import { useState } from "react"

import { BiPencil, BiSave } from "react-icons/bi";

const EditalComponent = (props) => {

    const [value, setValue] = useState(props.value);
    const [defaultValue, setDefaultValue] = useState(props.value);
    const [editing, setEditing] = useState(false);

    const saveEdit = () => {
        if(value.length > 0) {
            if(value !== defaultValue) {
                setDefaultValue(value);
                props.onSave(value);
            }
            setEditing(false);
        }
    }
    const keyPress = (event) => {
        if(props.saveOnEnter) {
            if(event.key === 'Enter') {
                saveEdit();
            }
        }
    }

    return <>
    
    <div className={"editablecomponent " + props.className}>
        <div className={"preview" + (editing ? ' hidden' : '')}>
            <span className="previewText txt">{defaultValue}</span>
            <span className="previewIcon" onClick={() => setEditing(true)}><BiPencil /></span>
        </div>
        <div className={"edit" + (!editing ? ' hidden' : '')}>
            <input type="text" value={value} onInput={(e) => setValue(e.currentTarget.value)}
                className="editText txt" placeholder={props.placeholder} 
                onKeyPress={keyPress} />
            <span className="editIcon" onClick={saveEdit}><BiSave /></span>
        </div>
    </div>
    
    </>

}

export default EditalComponent;