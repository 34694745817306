const Tooltip = (props) => {

    return <>
    
    <span className="tooltip">
        <span className="tooltip-content">
            {props.content}
        </span>
        {props.children}
    </span>
    
    </>

}
export default Tooltip;