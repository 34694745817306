const Lighthouse = (props) => {

    return <>
    
    <h1>Lighthouse</h1>

    <p>
        Die Erstellung von Website-Analyse mit Lighthouse ist derzeit nicht möglich.
    </p>

    </>

}

export default Lighthouse;