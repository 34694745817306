import { Link } from "react-router-dom"

const Events = () => {

    return <>
    
    <h1>Ereignisse</h1>

    <p>
        Um bestimmte Ereignisse wie Social-Media-Posts, Newsletter oder große Änderungen an
        der Website festzuhalten, können diese direkt 
        unter <Link to="/home/events">Ereignisse</Link> eingetragen werden. 
    </p>
    <br />
    <h2>Sortierung</h2>
    <p>
        Ereignisse, die sich innerhalb der definierten Zeitspanne befinden, sind nach den neusten 
        Ereignissen sortiert.
    </p>

    <br />
    <h2>Verbesserte Ansicht</h2>
    <p>
        Um die Ansicht so einfach und übersichtlich wie möglich zu gestalten, werden Posts
        auf sozialen Netzwerken durch das entsprechende Logo hervorgehoben. Folgende Dienste werden 
        unterstützt:
    </p>
    <div className="table">
        <table>
            <thead>
                <tr>
                    <td>Dienst</td>
                    <td>Nutzung</td>
                    <td>Anzeige</td>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>Instagram</td>
                    <td>Den Link zum Post in das Feld "Inhalt" einfügen</td>
                    <td>
                        <i>Eine integrierte Ansicht ist derzeit nicht verfügbar.</i><br />
                        Der Post kann über einen Button aufgerufen werden.
                    </td>
                </tr>
                <tr>
                    <td>Twitter</td>
                    <td>Den Link zum Tweet in das Feld "Inhalt" einfügen</td>
                    <td>
                        <i>Eine integrierte Ansicht ist derzeit nicht verfügbar.</i><br />
                        Der Tweet kann über einen Button aufgerufen werden.
                    </td>
                </tr>
            </tbody>
        </table>
    </div>

    <br />
    <p>
        Ereignisse, die keinem der oben aufgeführten Dieste zugeordnet werden können, werden 
        als normale aufklappbare Box angezeigt.
    </p>
    
    </>

}

export default Events;