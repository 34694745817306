import { useEffect, useState } from "react"
import { BsPencil } from "react-icons/bs";
import { useParams, Link, useHistory } from "react-router-dom";
import APIMan from "../../data/APIMan";
import { Pagination, PaginationItem } from "../home_pages/Reports";
import Modal, { ModalCloser, ModalContent } from "../Modal";
import { PageLoaderImage } from "../Pageloader";

const Sites = (props) => {

    const [list, setList] = useState([]);
    const [sites, setSites] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState("");
    const { siteID } = useParams();

    const [modalOpen, setModalOpen] = useState(false);
    const [modalLoading, setModalLoading] = useState(false);
    const [newSiteName, setNewSiteName] = useState("");
    const [newSiteURL, setNewSiteURL] = useState("");
    const [newSiteError, setNewSiteError] = useState("");

    const history = useHistory();

    useEffect(() => {
        updateTable();
    }, [props, siteID]);

    const updateTable = () => {
        setLoading(true);
        setError('');
        setList([]);
        setSites([]);
        var token = localStorage.getItem('auth_token') || "unset";
        APIMan.sendRequest('getsitelist.php?auth_token=' + token
        + '&offset=' + ((siteID-1) * 20) + '&amount=20', result => {
            if(result.error) {
                setError(result.errorMessage);
            }else {
                setError('');
                setList(result.data.list);
                setSites(result.data.sites);
            }
            setLoading(false);
        });
    }

    const createNewSite = () => {
        setModalLoading(true);
        var token = localStorage.getItem('auth_token') || "unset";
        APIMan.sendRequest('createsite.php?auth_token=' + token
        + '&name=' + newSiteName + '&url=' + newSiteURL, result => {
            if(result.error) {
                setNewSiteError(result.errorMessage);
                setModalLoading(false);
            }else {
                history.push('/profile/sites/' + result.data.id);
            }
        });
    }

    if(loading) {
        return <PageLoaderImage />
    }

    if(error !== "") {
        return <>
            <div className="alert error">{error}</div>        
        </>
    }

    return <>
    
        <div className="flex flex-between">
            <div>
                <h1>Seiten&shy;übersicht</h1>
            </div>
            <div>
                <button className="btn add" onClick={() => setModalOpen(true)}>Erstellen</button>
            </div>
        </div>

        <div className="table">
            <table>
                <thead>
                    <tr>
                        <td>Name</td>
                        <td>URL</td>
                    </tr>
                </thead>
                <tbody>
                    {list.map(site => {
                        return <tr key={site.id}>
                                <td><Link to={"/profile/sites/" + site.id}>
                                    {site.name} <BsPencil />
                                </Link></td>
                                <td>{site.url}</td>
                            </tr>
                    })}
                </tbody>
            </table>
        </div>

        <Modal open={modalOpen} loading={modalLoading} width={500}>
            <ModalCloser onClick={() => setModalOpen(false)} /> 
            <ModalContent>
                <h1>Neue Seite</h1>
                <p>
                    Nach dem Erstellen der Seite müssen folgende Daten ergänzt werden, damit 
                    Reports erstellt werden können:
                </p>
                <ul>
                    <li>Matomo Seiten-ID</li>
                    <li>Matomo API-Token</li>
                </ul>

                <br />

                <div className="formfield">
                    <input type="text" id="sitename" autoComplete="off"
                        value={newSiteName} onInput={e => setNewSiteName(e.currentTarget.value)} required />
                    <label htmlFor="sitename">Seitenname</label>
                </div>

                <div className="formfield">
                    <input type="text" id="url" autoComplete="off"
                        value={newSiteURL} onInput={e => setNewSiteURL(e.currentTarget.value)} required />
                    <label htmlFor="url">URL</label>
                </div>
                <small>Bsp.: www.meine-website.de</small>

                <br />

                {newSiteError && <div className="alert error">{newSiteError}</div>}
                
                <br />

                <p>
                    <button 
                        className="btn"
                        onClick={() => createNewSite()}
                    >
                        Seite erstellen
                    </button>
                </p>

            </ModalContent>
        </Modal>

        <Pagination>
            {sites.map(site => {
                return <PaginationItem key={site}
                    to={'/profile/sites/site/' + site}
                    active={site === parseInt(siteID)}>
                    {site}
                </PaginationItem>
            })}
        </Pagination>
    
    </>

}

export default Sites;