const Keywords = (props) => {

    return <>

    <h1>Keywords</h1>
    <p>
        Die Top 100 Keywords sind aufgeteilt in 3 Listen:
    </p>
    <ol>
        <li>Top-Keywords</li>
        <li>Potenzielle Keywords</li>
        <li>Ideen</li>
    </ol>
    <br />
    <h2>Top-Keywords</h2>
    <p>
        Unter den Top-Keywords werden alle Keywords aufgelistet, die für die Seite auf den Plätzen
        1 bis 5 auf Google geranked werden.
    </p>
    <br />
    <h2>Potenzielle Keywords</h2>
    <p>
        Alle Keywords auf den Plätzen 6 bis 10 werden als potenzielle Keywords eingestuft, da 
        die Seite dort zwar auf Seite 1 angezeigt wird, allerdings weit unten.
    </p>
    <br />
    <h2>Ideen</h2>
    <p>
        Alle weiteren Keywords befinden sich unter Ideen. Unter diesen Keywords wird die Seite zwar 
        gefunden, allerdings nicht auf der ersten Seite. Bei der Beurteilung, ob dieses Keyword wichtig 
        werden könnte, hilft die Keyword-Ampel.
    </p>
    <br />
    <h2>Keyword-Ampel</h2>
    <p>
        Die Keyword-Ampel zeigt anhand verschiedenen Farben die Relevanz eines Keywords. 
    </p>
    <p>
        <span className="kw_light red"></span> Keine Relevanz
    </p>
    <p>
        <span className="kw_light orange"></span> Nicht empfohlen
    </p>
    <p>
        <span className="kw_light green"></span> Empfohlen
    </p>
    <p>
        <span className="kw_light lightgreen"></span> Hohe Relevanz
    </p>
    <div className="alert warning">
        <b>Hinweis:</b> Die Relevanz bezieht sich auf den Durchschnittswert der Top 100 Keywords.
    </div>
    
    </>

}

export default Keywords;