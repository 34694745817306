import React, { useEffect, useState } from 'react';
import UserData from './data/UserData';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect
} from "react-router-dom";
import NavigationBar, { OuterNavigationBar } from './components/navigation/NavigationBar';
import Sidebar, { SidebarItem } from './components/sidebar/Sidebar';
import Login from './components/login/Login';
import SupportUse from './components/support_pages/SupportUse';
import SupportAPI from './components/support_pages/SupportAPI';
import Home from './components/home_pages/Home';
import Reports from './components/home_pages/Reports';
import Report from './components/home_pages/Report';
import SupportKeywords from './components/support_pages/SupportKeywords';
// import Events from './components/home_pages/Events';
import { BiCalendar, BiCalendarEvent, BiCodeBlock, BiFolderOpen, BiGroup, BiHelpCircle, BiHome, BiInfoCircle, BiKey, BiLock, BiMessage, BiMouse, BiSpreadsheet, BiTargetLock, BiTime, BiUser } from 'react-icons/bi';
import ViewValidation from './components/ViewValidation';
import Crons from './components/home_pages/Crons';
import { BsLightning } from 'react-icons/bs';
import Users from './components/profile_pages/Users';
import User from './components/profile_pages/User';
import Sites from './components/profile_pages/Sites';
import Site from './components/profile_pages/Site';
import SupportCrons from './components/support_pages/SupportCrons';
import SupportEvents from './components/support_pages/SupportEvents';
import SupportLighthouse from './components/support_pages/SupportLighthouse';
import BorderAnimationLogo from './KraussLogoBorderAnimation3.gif';
import Lighthouse from './components/home_pages/Lighthouse';
import Goals from './components/home_pages/Goals';
import Profile from './components/profile_pages/Profile';
import APIMan from './data/APIMan';
import Year from './components/home_pages/Year';

import './sass/vars.scss';
import Access from './components/profile_pages/Access';
import Event from './components/home_pages/Event';

const App = (props) => {

  const [loading, setLoading] = useState(true);
  const [needAuth, setNeedAuth] = useState(false);
  const [actionPerms, setActionPerms] = useState({});

  useEffect(() => {
    var token = localStorage.getItem('auth_token') || "unset";
    if(token === "unset"){
      setNeedAuth(true);
    }else {
      APIMan.sendRequest('checklogin.php?auth_token=' + token, function(result) {
        if(result.error) {
          setNeedAuth(true);
        }else {
          setNeedAuth(false);
          UserData.userName = result.data.username;
          UserData.userNickname = result.data.nickname;
          UserData.userMail = result.data.usermail;
          UserData.userImage = result.data.image;
          setActionPerms(result.data.permissions);
        }
      });
    }
    setTimeout(() => {
      setLoading(false);
    }, 1200);
  }, []);

  if(loading){
    return <>
      <div id="mainpageloader-image"><img src={BorderAnimationLogo} alt="LOADING" /></div>
    </>
  }

  if(needAuth){
    return <>
      <Router>
        <Switch>

          <Route path="/view/:reportID">
            <OuterNavigationBar />
            <div id="main-content-out">
              <ViewValidation />
            </div>
          </Route>
          <Route path="*">
            <Login />
          </Route>

        </Switch>
      </Router>
    </>
  }

  return (
    <Router>

      <Route path="/logout" exact render={() => {
        localStorage.setItem('auth_token', "");
        window.location.href = "/";
        return "";
      }} />

      <Switch>

        <Route path="/view/:reportID">
            <OuterNavigationBar />
            <div id="main-content-out">
              <ViewValidation />
            </div>
        </Route>

        <Route path="/">

          {!window.ePopup && <NavigationBar />}
          {window.ePopup && <OuterNavigationBar />}

          <Route path="/" exact><Redirect to="/home" /></Route>

          {!window.ePopup && <Route path="/home">
            <><Sidebar>
              <SidebarItem to="/home" icon={<BiHome />} exact>Übersicht</SidebarItem>
              <SidebarItem to="/home/reports" icon={<BiSpreadsheet />}>Reports</SidebarItem>
              <SidebarItem to="/home/year" icon={<BiCalendar />}>Jahresübersicht</SidebarItem>
              <SidebarItem to="/home/goals" icon={<BiTargetLock />}>Ziele</SidebarItem>
              <SidebarItem to="/home/lighthouse" icon={<BsLightning />}>Lighthouse</SidebarItem>
              <SidebarItem to="/home/schedule" icon={<BiTime />}>Automatisierung</SidebarItem>
            </Sidebar></>
          </Route>}

          {!window.ePopup && <Route path="/support">
            <Sidebar>
              <SidebarItem exact to="/support/" icon={<BiHelpCircle />}>Einführung</SidebarItem>
              <SidebarItem to="/support/use" icon={<BiMouse />}>Hinweise zur Benutzung</SidebarItem>
              <SidebarItem to="/support/reports" icon={<BiSpreadsheet />}>Reports</SidebarItem>
              <SidebarItem to="/support/keywords" icon={<BiKey />}>Keywords</SidebarItem>
              <SidebarItem to="/support/events" icon={<BiCalendarEvent />}>Ereignisse</SidebarItem>
              <SidebarItem to="/support/year" icon={<BiCalendar />}>Jahresübersicht</SidebarItem>
              <SidebarItem to="/support/goals" icon={<BiTargetLock />}>Ziele</SidebarItem>
              <SidebarItem to="/support/lighthouse" icon={<BsLightning />}>Lighthouse</SidebarItem>
              <SidebarItem to="/support/schedule" icon={<BiTime />}>Automatisierung</SidebarItem>
              <SidebarItem to="/support/privacy" icon={<BiLock />}>Datenschutz</SidebarItem>
              <SidebarItem to="/support/legal" icon={<BiInfoCircle />}>Rechtliche Hinweise</SidebarItem>
              <SidebarItem to="/support/api" icon={<BiCodeBlock />}>API Dokumentation</SidebarItem>
            </Sidebar>
          </Route>}

          {!window.ePopup && <Route path="/profile">
            <Sidebar>
              <SidebarItem to="/profile" icon={<BiUser />} exact>Übersicht</SidebarItem>
              <SidebarItem to="/profile/access" icon={<BiKey />}>Zugangsdaten</SidebarItem>
              <SidebarItem to="/profile/notifications" icon={<BiMessage />}>Benachrichtigungen</SidebarItem>
              {actionPerms['manageusers'] &&
                <SidebarItem to="/profile/users" icon={<BiGroup />}>Benutzerverwaltung</SidebarItem>}
              {actionPerms['managesites'] && 
                <SidebarItem to="/profile/sites" icon={<BiFolderOpen />}>Seitenverwaltung</SidebarItem>}
            </Sidebar>
          </Route>}

          <div id={"main-content" + (window.ePopup ? ' popup' : '')} style={
            (window.ePopup ? {padding: '20px'} : {})
          }>
            {(localStorage.getItem('csid') === undefined ||
              localStorage.getItem('csid') === "" ||
              localStorage.getItem('csid') === null) && 
            <div className="alert error">
              <b>Achtung:</b> Um Zugriff auf alle Funktionen des Systems zu erhalten, muss eine 
              Seite ausgewählt werden.
            </div>}

            <Switch>

              <Route path="/home" exact><Home /></Route>
              {/* <Route path="/home/events" exact><Events /></Route> */}
              <Route path="/home/events" exact><Redirect to="/home/year" /></Route>
              <Route path="/home/event/:eventID"><Event /></Route>
              <Route path="/home/year" exact><Year /></Route>
              <Route path="/home/reports" exact><Redirect to="/home/reports/site/1" /></Route>
              <Route path="/home/reports/site/:siteID" exact><Reports /></Route>
              <Route path="/home/reports/:reportID"><Report /></Route>
              <Route path="/home/goals" exact><Redirect to="/home/goals/site/1" /></Route>
              <Route path="/home/goals/site/:siteID"><Goals /></Route>
              <Route path="/home/lighthouse"><Lighthouse /></Route>
              <Route path="/home/schedule"><Crons /></Route>

              <Route path="/support/use"><SupportUse /></Route>
              <Route path="/support/keywords"><SupportKeywords /></Route>
              <Route path="/support/events"><SupportEvents /></Route>
              <Route path="/support/lighthouse"><SupportLighthouse /></Route>
              <Route path="/support/schedule"><SupportCrons /></Route>
              <Route path="/support/api"><SupportAPI /></Route>

              <Route path="/profile" exact><Profile /></Route>
              <Route path="/profile/access" exact><Access /></Route>
              <Route path="/profile/users" exact><Redirect to="/profile/users/site/1" /></Route>
              <Route path="/profile/users/:userID" exact><User /></Route>
              <Route path="/profile/users/site/:siteID" exact><Users /></Route>

              <Route path="/profile/sites" exact><Redirect to="/profile/sites/site/1" /></Route>
              <Route path="/profile/sites/:siteID" exact><Site /></Route>
              <Route path="/profile/sites/site/:siteID" exact><Sites /></Route>

              <Route path="*">
                <center>
                  <h1>404 Not found</h1>
                  <p>
                    Es tut uns leid, jedoch wurde diese Seite nicht gefunden.
                  </p>
                  <br /><br />
                  <p>
                    <Link to="/" className="btn outline">Zum Dashboard</Link>
                  </p>
                </center>
              </Route>

            </Switch>

            {window.ePopup && <><br /><br /><center><button className="btn simple" onClick={() => window.close()}>
              Fenster schließen</button></center></>}

          </div>

        </Route>
      </Switch>

    </Router>
  );

}

export default App;