import { useEffect, useState } from "react";
import { BsTrashFill } from "react-icons/bs";
import APIMan from "../../data/APIMan";
import Modal, { ModalCloser, ModalContent } from "../Modal";
import { PageLoaderImage } from "../Pageloader";
import SupportWindow from "../support_pages/SupportWindow";

const Crons = (props) => {

    const [crons, setCrons] = useState([]);
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(true);

    const [newModalOpen, setNewModalOpen] = useState(false);
    const [newModalLoading, setNewModalLoading] = useState(false);
    const [newError, setNewError] = useState("");
    const [newDay, setNewDay] = useState("*");
    const [newWeekday, setNewWeekday] = useState("*");
    const [newMonth, setNewMonth] = useState("*");
    const [newTask, setNewTask] = useState("none");
    const [newTaskCRduration, setNewTasksCRduration] = useState(7);
    const [newName, setNewName] = useState("");
    const [last, setLast] = useState([]);

    useEffect(() => {
        reloadTable();    
    }, [props]);

    const reloadTable = () => {
        setLoading(true);
        setError('');
        setCrons([]);
        setLast([]);
        var token = localStorage.getItem('auth_token') || "unset";
        var site = localStorage.getItem('csid') || "unset";
        APIMan.sendRequest('getcronjobs.php?auth_token=' + token + '&site_token=' + site, result => {
            if(result.error) {
                setError(result.errorMessage);
            }else {
                setError('');
                setCrons(result.data.list);
                setLast(result.data.last);
            }
            setLoading(false);
        });
    }

    if(loading) {
        return <> <PageLoaderImage /> </>
    }

    if(error !== "") {
        return <>
            <div className="alert error">{error}</div>        
        </>
    }

    const selectChangeEvent = (event, callback) => {
        callback((event.target).querySelector('option:checked[value]').value);
    }

    const createCron = () => {
        setNewModalLoading(true);
        setNewError('');
        var token = localStorage.getItem('auth_token') || "unset";
        var site = localStorage.getItem('csid') || "unset";
        var taskData = {};
        if(newTask === 'create_report'){
            taskData = {
                duration: newTaskCRduration
            };
        }else if(newTask === 'check_goals'){
            taskData = {};
        }else {
            setNewError('Bitte Aufgabe wählen!');
            setNewModalLoading(false);
            return;
        }

        APIMan.sendRequest('createcronjob.php?auth_token=' + token
        + '&site_token=' + site + '&day=' + newDay + '&weekday=' + newWeekday + '&month=' + newMonth
        + '&task=' + newTask + '&taskData=' + JSON.stringify(taskData) + '&name=' + newName, result => {
            if(result.error) {
                setNewError(result.errorMessage);
            }else {
                setNewModalOpen(false);
                reloadTable();
                setNewDay('*');
                setNewMonth('*');
                setNewWeekday('*');
                setNewTask('none');
            }
            setNewModalLoading(false);
        });
    }

    const deleteCronjob = (id) => {
        setLoading(true);
        var token = localStorage.getItem('auth_token') || "unset";
        var site = localStorage.getItem('csid') || "unset";

        APIMan.sendRequest('deletecron.php?auth_token=' + token + '&site_token=' + 
        site + '&cron=' + id, result => {
            if(result.error) {
                setError(result.errorMessage);
            }else {
                setError('');
                reloadTable();
            }
            setLoading(false);
        });
    }

    return <>

    <div className="flex flex-between">
        <div>
            <h1>Automatische Aufgaben</h1>
        </div>
        <div>
            <button className="btn add" onClick={() => setNewModalOpen(true)}>Neu</button>
        </div>
    </div>

    {last.length > 0 && <>
        <h2>Letzte 24 Stunden</h2>
        {last.map(job => {
            if(job.state === "0") {
                return <div className="alert error">
                    <b>Fehler:</b> Aufgabe {job.name} wurde nicht erfolgreich ausgeführt. <br />
                    <small>Start: {job.time}</small>
                </div>
            }else if(job.state === "1") {
                return <div className="alert success">
                    <b>Erfolg:</b> Aufgabe {job.name} wurde erfolgreich ausgeführt. <br />
                    <small>Start: {job.time}, Dauer: {job.duration} s</small>
                </div>
            }else {
                return <div className="alert warning">
                    <b>Fehler:</b> Unbekannter Status für Report {job.name}
                </div>
            }
        })}
        <br />
    </>}
    
    <div className="table">
        <table>
            <thead>
                <tr>
                    <td>Name</td>
                    <td>Aktion</td>
                    <td>Ausführung</td>
                    <td></td>
                </tr>
            </thead>
            <tbody>
                {crons.map(cron => {
                    return <tr key={cron.id}>
                        <td>{cron.name}</td>
                        <td>{cron.task} <br /> <small>{cron.taskData}</small></td>
                        <td>{cron.time}</td>
                        <td>
                            <button className="btn simple"
                                onClick={() => deleteCronjob(cron.id)}>
                                <BsTrashFill />
                            </button>
                        </td>
                    </tr>
                })}
            </tbody>
        </table>
    </div>

    <Modal width={500} open={newModalOpen} loading={newModalLoading}>
        <ModalCloser onClick={() => setNewModalOpen(false)} /> 
        <ModalContent>
            <h1>Neue Aufgabe</h1>
            <p>
                Hier können Aufgaben erstellt werden, die automatisch vom System zu bestimmten
                Zeiten ausgeführt werden.<br />
                Dadurch können beispielsweise automatisch Reports 
                angelegt werden.
            </p>

            <p>
                <SupportWindow link="/support/schedule">
                    Mehr Informationen zur Erstellung von Aufgaben
                </SupportWindow>
            </p>

            <br />

            <div className="formfield">
                <input type="text" id="taskname" name={newName} 
                    onChange={(e) => setNewName(e.currentTarget.value)} required />
                <label htmlFor="taskname">Name der Aufgabe</label>
            </div>

            <br />

            <p>
                Zeitpunkt der Ausführung (wiederholend):
            </p>

            <div className="flex flex-inline flex-between flex-spacing">
                
                <div className="formfield">
                    <select onChange={(e) => selectChangeEvent(e, setNewDay)} id="day">
                        <option value="*">Jeden Tag</option>
                        <option value="1">01</option>
                        <option value="2">02</option>
                        <option value="3">03</option>
                        <option value="4">04</option>
                        <option value="5">05</option>
                        <option value="6">06</option>
                        <option value="7">07</option>
                        <option value="8">08</option>
                        <option value="9">09</option>
                        <option value="10">10</option>
                        <option value="11">11</option>
                        <option value="12">12</option>
                        <option value="13">13</option>
                        <option value="14">14</option>
                        <option value="15">15</option>
                        <option value="16">16</option>
                        <option value="17">17</option>
                        <option value="18">18</option>
                        <option value="19">19</option>
                        <option value="20">20</option>
                        <option value="21">21</option>
                        <option value="22">22</option>
                        <option value="23">23</option>
                        <option value="24">24</option>
                        <option value="25">25</option>
                        <option value="26">26</option>
                        <option value="27">27</option>
                        <option value="28">28</option>
                        <option value="29">29</option>
                        <option value="30">30</option>
                        <option value="31">31</option>
                    </select>
                    <label htmlFor="day">Tag im Monat</label>
                </div>

                <div className="formfield">
                    <select onChange={(e) => selectChangeEvent(e, setNewMonth)} id="month">
                        <option value="*">Jeder Monat</option>
                        <option value="1">Januar</option>
                        <option value="2">Februar</option>
                        <option value="3">März</option>
                        <option value="4">April</option>
                        <option value="5">Mai</option>
                        <option value="6">Juni</option>
                        <option value="7">Juli</option>
                        <option value="8">August</option>
                        <option value="9">September</option>
                        <option value="10">Oktober</option>
                        <option value="11">November</option>
                        <option value="12">Dezember</option>
                    </select>
                    <label htmlFor="month">Monat</label>
                </div>

                <div className="formfield">
                    <select onChange={(e) => selectChangeEvent(e, setNewWeekday)} id="weekday">
                        <option value="*">Jeden Wochentag</option>
                        <option value="1">Montags</option>
                        <option value="2">Dienstags</option>
                        <option value="3">Mittwochs</option>
                        <option value="4">Donnerstags</option>
                        <option value="5">Freitags</option>
                        <option value="6">Samstags</option>
                        <option value="7">Sonntags</option>
                    </select>
                    <label htmlFor="weekday">Wochentag</label>
                </div>

            </div>

            <br />

            <p>
                Aufgabe:
            </p>

            <div className="flex flex-between">
                <div>
                    <div className="formfield">
                        <select onChange={(e) => selectChangeEvent(e, setNewTask)}>
                            <option value="none">-- wählen --</option>
                            <option value="create_report">Report erstellen</option>
                            <option value="check_goals">Überprüfung der Ziele</option>
                        </select>
                    </div>
                </div>
                <div>

                    {newTask === 'create_report' && <div>
                        <h3>Dauer des Reports</h3>
                        Der Report umfasst die letzten <input type="number" 
                        value={newTaskCRduration} onChange={(e) => 
                        setNewTasksCRduration(e.currentTarget.value)} /> Tage.
                    </div>}

                </div>
            </div>

            {newError && <div className="alert error">{newError}</div>}
            
            <br />

            <p>
                <button 
                    className="btn"
                    onClick={() => createCron()}
                >
                    Aufgabe hinzufügen
                </button>
            </p>
        </ModalContent>
    </Modal>

    </>

}

export default Crons;