import { Link } from "react-router-dom"

const Usage = (props) => {

    return <>
    
    <h1>Benutzung</h1>
    <ol>
        <li>Seitenauswahl</li>
        <li>Navigation</li>
        <li>Profileinstellungen</li>
        <li>Abmeldung</li>
    </ol>
    <br />
    <h2>Seitenauswahl</h2>
    <p>
        Um Zugriff auf das Dashboard, die Reports und vieles weitere zu erhalten, ist es erforderlich,
        eine bestimmte Seite auszuwählen. Diese Auswahl kann mit einem Klick auf das Profilbild 
        (oben rechts) und die entsprechende Seite getroffen werden.
    </p>
    <p>
        Es ist möglich, dass auf unterschiedlichen Seiten verschiedene Berechtigungen vergeben sind.
    </p>
    <br />
    <h2>Navigation</h2>
    <p>
        Die Haupt-Menüpunkte sind in der oberen Navigationsleiste zu finden. Die Unterseiten befinden 
        sich im Menü auf der linken Seite (Desktop) oder unter der Navigationsleiste (Mobil) auf den 
        entsprechenden Seiten.
    </p>
    <br />
    <h2>Profileinstellungen</h2>
    <p>
        Einstellungen zum Profil wie Anmeldedaten und Benachrichtigungen können auf der Profilseite 
        verwaltet werden.
    </p>
    <br />
    <h2>Abmeldung</h2>
    <p>
        Nach jeder Sitzung ist eine Abmeldung empfohlen. Die Abmeldung ist über das Profilbild 
        oben rechts möglich.
    </p>
    </>

}

export default Usage;