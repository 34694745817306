import BorderAnimationLogo from '../KraussLogoBorderAnimation3.gif';

const Pageloader = (props) => {

    return <>
    
    <div className="pageloader">
        
    </div>
    
    </>

}
export default Pageloader;

export function PageLoaderImage(props) {
    return <>
    
    <div className="pageloader-image">
        <img src={BorderAnimationLogo} />
    </div>
    
    </>
}