// import { useEffect } from "react";
// import logo512 from '../../logo512.png';

const Lighthouse = (props) => {

    /**
     * Just for testing
     * this works but thats not what i want
     * i have no idea how to get this working
     * i need a push server
     * and a service worker
     * ahhhh :(
     */
    /*useEffect(() => {

        Notification.requestPermission().then(function(result) {
            if(result === 'granted') {

                var title = 'Huhu!';
                var options = {
                    body: 'Hello World, what\'s up?',
                    icon: logo512,
                    vibrate: [200, 100, 200],
                };
                new Notification(title, options);

            }
        });

    }, []);*/
 
    return <>

    <div className="alert warning">
        <h3>Lighthouse ist aktuell nicht verfügbar</h3>
    </div>

    <div className="loading-spinner"></div>

    </>
    
}

export default Lighthouse;