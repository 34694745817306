import { MdOpenInNew } from 'react-icons/md';

const Window = (props) => {
    const openWindow = (event) => {
        event.preventDefault();
        var windowWidth = 480 >= window.top.outerWidth ? window.top.outerWidth - 20 : 600;
        var windowHeight = 620 >= window.top.outerHeight ? window.top.outerHeight - 20 : 800;
        var posTop = window.top.outerHeight / 2 + window.top.screenY - ( windowHeight / 2);
        var posLeft = window.top.outerWidth / 2 + window.top.screenX - ( windowWidth / 2);
        var newWindow = window.open(props.link, 'Support', `toolbar=no,
        location=no,
        status=no,
        menubar=no,
        scrollbars=yes,
        resizable=no,
        width=${windowWidth},
        height=${windowHeight},
        left=${posLeft},
        top=${posTop}`);
        newWindow.ePopup = true;
        if(window.focus) newWindow.focus();
        newWindow.is_opened_as_support = true;
        return false;
    }

    return <>
    
        <a href={props.link} onClick={openWindow}>{props.children} <MdOpenInNew /></a>
    
    </>
}

export default Window;