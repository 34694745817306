import { useEffect, useState } from "react";
import APIMan from "../../data/APIMan";

const LoginScreen = (props) => {

    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const login = () => {
        setLoading(true);
        APIMan.sendRequest('login.php?username=' + username + '&password=' + password, result => {
            console.log(result);
            if(result.error) {
                setError(true);
                setErrorMessage(result.errorMessage);
            }else {
                localStorage.setItem('auth_token', result.data.auth_token);
                window.location.reload();
            }
            setLoading(false);
        });
    }

    return <>
    
        <div id="loginscreen">

            <div className={"loginbox" + (loading ? " loading" : "")}>

                <center>
                    <img src="https://www.krausskommunikation.de/wp-content/themes/krauss2020/images/logo.svg" />
                    <br />
                    <h3>Bitte melden Sie sich an.</h3>
                </center>

                <br />

                {error && <div className="error">{errorMessage}</div>}

                <div className="formfield">
                    <input type="text" id="username" name="username" autoComplete="no"
                        value={username} onInput={e => setUsername(e.currentTarget.value)} required />
                    <label for="username">Benutzername</label>
                </div>

                <div className="formfield">
                    <input type="password" id="password" name="password" 
                        value={password} onInput={e => setPassword(e.currentTarget.value)} required />
                    <label for="password">Passwort</label>
                </div>

                <br />

                <button className="btn block" onClick={login}>Anmelden</button>

                <br />

                <p style={{opacity: "0.3"}}>
                    <small>
                        <i>
                            Bei Problemen mit Ihrem Account, kontaktieren Sie uns bitte unter <u>web@krausskommunikation.de</u>.
                        </i>
                    </small>
                </p>

            </div>

        </div>
    
    </>

}
export default LoginScreen;