import { useEffect, useState } from "react";
import APIMan from "../../data/APIMan";
import { PageLoaderImage } from "../Pageloader";
import twitterlogo from '../../images/twitterlogo.svg';
import instagramlogo from '../../images/instagramlogo.webp';
import { BsTrashFill } from "react-icons/bs";

const { useParams, useHistory } = require("react-router-dom");

function Event(props) {

    const { eventID } = useParams();
    const history = useHistory();

    const [data, setData] = useState({});
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState("");

    useEffect(() => {

        var token = localStorage.getItem('auth_token') || 'unset';
        var site = localStorage.getItem('csid') || 'unset';
        APIMan.sendRequest('getevent.php?auth_token=' + token + '&site_token=' + site + '&event=' + eventID, result => {
            if(result.error) {
                setError(result.errorMessage);
            }else {
                setData(result.data);
            }
            setLoading(false);
        });

    }, [props]);



    const deleteEvent = (event) => {
        setLoading(true);
        var token = localStorage.getItem('auth_token') || 'unset';
        var site = localStorage.getItem('csid') || 'unset';
        APIMan.sendRequest('deleteevent.php?auth_token=' + token + '&site_token=' + site 
        + '&event=' + event, result => {
            if(result.error) {
                setError(result.errorMessage);
            }else {
                setError('');
                history.push('/home/year');
            }
            setLoading(false);
        });
    }

    if(loading) return <PageLoaderImage />

    return <>
    
        {error !== "" && <div className="alert error">{error}</div>}

        {data.type === 'plain' && <EventPost title={data.title} text={data.content.content}
                        date={data.date.first} year={data.date.second}
                        onDelete={() => deleteEvent(data.id)} key={data.id} />}

        {data.type === 'twitter' && <EventPostTwitter title={data.title} link={data.content.link}
                        date={data.date.first} year={data.date.second} 
                        onDelete={() => deleteEvent(data.id)} key={data.id} />}

        {data.type === 'instagram' && <EventPostInstagram title={data.title} link={data.content.link}
                        date={data.date.first} year={data.date.second}
                        onDelete={() => deleteEvent(data.id)} key={data.id} />}

    </>

}

export function EventPost(props) {

    return <>
    
    <div className={"event insta-post active"}>
        <span className="date">
            <span className="line1">{props.date}</span>
            <span className="line2">{props.year}</span>
        </span>
        <div>
            <h3>{props.title}</h3>
            <div className="cont">
                <br />
                <p>{props.text}</p>
                <br />
                <br />
                <button className="btn simple" onClick={() => {
                    if(props.onDelete) props.onDelete();
                }}>
                    <BsTrashFill /> Ereignis löschen
                </button>
            </div>
        </div>
    </div>
    
    </>
}

export function EventPostInstagram(props) {

    const [data, setData] = useState({});
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        /*fetch(props.link + '?__a=1').then(data => data.json()).then(json => {
            setData({
                username: '@' + json.graphql.shortcode_media.owner.username,
                media: json.graphql.shortcode_media.display_resources[0].src,
                text: json.graphql.shortcode_media.edge_media_to_caption.edges[0].node.text
            });
            setLoading(false);
        }).catch((error) => {*/
            setData({
                username: 'instagram.com',
                media: instagramlogo,
                text: <>
                    <a href={props.link} className="openWin"
                        onClick={openInstagram}>Post ansehen</a>
                        <br /><br />
                        <small>
                            <i>Eine Ansicht des Posts in dieser Oberfläche ist derzeit nicht
                                möglich.</i>
                        </small>
                </>
            });
            setLoading(false);
        // });
    }, [props]);

    const openInstagram = (event) => {
        event.preventDefault();
        var windowWidth = 600 >= window.top.outerWidth ? window.top.outerWidth - 20 : 600;
        var windowHeight = 800 >= window.top.outerHeight ? window.top.outerHeight - 20 : 800;
        var posTop = window.top.outerHeight / 2 + window.top.screenY - ( windowHeight / 2);
        var posLeft = window.top.outerWidth / 2 + window.top.screenX - ( windowWidth / 2);
        var newWindow = window.open(props.link, 'Post ansehen', `toolbar=no,
        location=no,
        status=no,
        menubar=no,
        scrollbars=yes,
        resizable=no,
        width=${windowWidth},
        height=${windowHeight},
        left=${posLeft},
        top=${posTop}`);
        if(window.focus) newWindow.focus();
        return false;
    }

    if(loading) {
        return <>
            <div className="event loading">
                <span></span>
            </div>
        </>
    }

    return <>
    
    <div className={"event insta-post active"}>
        <span className="date">
            <span className="line1">{props.date}</span>
            <span className="line2">{props.year}</span>
        </span>
        <div>
            <h3>{props.title}</h3>
            <span>{data.username}</span>
            <div className="cont">
                <br />
                <p>{data.text}</p>
                <br />
                <br />
                <button className="btn simple" onClick={() => {
                    if(props.onDelete) props.onDelete();
                }}>
                    <BsTrashFill /> Ereignis löschen
                </button>
            </div>
        </div>
        <div>
            <img src={data.media} />
        </div>
    </div>
    
    </>

}

export function EventPostTwitter(props) {

    const [data, setData] = useState({});
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setData({
            content: props.link
        });
        setLoading(false);
    }, [props]);

    if(loading) {
        return <>
            <div className="event loading">
                <span></span>
            </div>
        </>
    }

    const openTweet = (event) => {
        event.preventDefault();
        var windowWidth = 600 >= window.top.outerWidth ? window.top.outerWidth - 20 : 600;
        var windowHeight = 800 >= window.top.outerHeight ? window.top.outerHeight - 20 : 800;
        var posTop = window.top.outerHeight / 2 + window.top.screenY - ( windowHeight / 2);
        var posLeft = window.top.outerWidth / 2 + window.top.screenX - ( windowWidth / 2);
        var newWindow = window.open(props.link, 'Tweet ansehen', `toolbar=no,
        location=no,
        status=no,
        menubar=no,
        scrollbars=yes,
        resizable=no,
        width=${windowWidth},
        height=${windowHeight},
        left=${posLeft},
        top=${posTop}`);
        if(window.focus) newWindow.focus();
        return false;
    }

    return <>
    
    <div className={"event twitter-post active"}>
        <span className="date">
            <span className="line1">{props.date}</span>
            <span className="line2">{props.year}</span>
        </span>
        <div>        
            <h3>{props.title}</h3>
            <span>twitter.com</span>
            <div className="cont">
                <br />
                <p>
                <a href={data.content} className="openWin"
                    onClick={openTweet}>Tweet ansehen</a>
                    <br /><br />
                    <small>
                        <i>Eine Ansicht der Tweets in dieser Oberfläche wird noch nicht 
                            unterstützt.</i>
                    </small>
                </p>
                <br />
                <br />
                <button className="btn simple" onClick={() => {
                    if(props.onDelete) props.onDelete();
                }}>
                    <BsTrashFill /> Ereignis löschen
                </button>
            </div>
        </div>
        <div>
            <img src={twitterlogo} />
        </div>
    </div>
    
    </>

}

export default Event;