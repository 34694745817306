import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import EditalComponent from "../EditalComponent";
import { PageLoaderImage } from "../Pageloader";
import { Bar, Doughnut, Line } from "react-chartjs-2";

import Modal, { ModalCloser, ModalContent } from '../Modal';
import { BiBarChart, BiDoughnutChart, BiLineChart, BiSpreadsheet, BiTable } from "react-icons/bi";
import SupportWindow from "../support_pages/SupportWindow";
import APIMan from "../../data/APIMan";

const Report = (props) => {

    const [report, setReport] = useState({});
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(true);

    const [errorModal, setErrorModal] = useState(false);
    const [errorModalText, setErrorModalText] = useState('');

    const { reportID } = useParams();
    const history = useHistory();

    const [cd, setCD] = useState({});

    const [chartViewAccess, setChartViewAccess] = useState(true);
    const [chartViewDevice, setChartViewDevice] = useState(true);
    const [kwTopShort, setKwTopShort] = useState(true);
    const [kwPotShort, setKwPotShort] = useState(true);
    const [kwIdeasShort, setKwIdeasShort] = useState(true);

    useEffect(() => {
        var token = localStorage.getItem('auth_token') || "unset";
        var site = localStorage.getItem('csid') || "unset";
        if(props.viewOnly){
          token = "view_only"; site = "view_only";
        }
        APIMan.sendRequest('getreport.php?auth_token=' + token + 
        '&report=' + reportID + '&site_token=' + site, result => {
          if(result.error) {
            setError(result.errorMessage);
          }else {
            var report = result.data;
            var dayArray = [];
            var dayVisitorArray = [];
            var dayNewArray = [];
            var dayBounceArray = [];
            var dayConversions = [];
            var deviceArray = [];
            var deviceAccess = [];
            var deviceData = {};
            var timesLabels = [];
            var timesAccess = [];
            var timesActions = [];
            var timesData = {};
            var timeAverageVisitors = [];
            var dayReferrersSearch = [[], [], [], [], []];
            for(var obj in report['content']['sites']['days']) {
              dayReferrersSearch[0].push(report['content']['sites']['days'][obj]['referrers']['searchengines']);
              dayReferrersSearch[1].push(report['content']['sites']['days'][obj]['referrers']['socialmedia']);
              dayReferrersSearch[2].push(report['content']['sites']['days'][obj]['referrers']['direct']);
              dayReferrersSearch[3].push(report['content']['sites']['days'][obj]['referrers']['websites']);
              dayReferrersSearch[4].push(report['content']['sites']['days'][obj]['referrers']['campaigns']);
              dayArray.push(obj);
              dayVisitorArray.push(report['content']['sites']['days'][obj]['visitors']['total']);
              dayNewArray.push(report['content']['sites']['days'][obj]['visitors']['new']);
              dayConversions.push(report['content']['sites']['days'][obj]['conversions']['total']);
              dayBounceArray.push(
                report['content']['sites']['days'][obj]['visitors']['total'] * report['content']['sites']['days'][obj]['bouncerate']
              );
              for(var dev in report['content']['sites']['days'][obj]['devices']) {
                var devData = report['content']['sites']['days'][obj]['devices'][dev];
                if(deviceData[dev] > 0) {
                  deviceData[dev] += devData['visitors'];
                }else {
                  deviceData[dev] = devData['visitors'];
                }
              }
              for(var time in report['content']['sites']['days'][obj]['times']) {
                var timeData = report['content']['sites']['days'][obj]['times'][time];
                if(timesData[time] instanceof Array) {
                  timesData[time] = [timesData[time][0] + timeData['visitors'], timesData[time][0] + timeData['actions']];
                }else {
                  timesData[time] = [timeData['visitors'], timeData['actions']];
                }
              }
            }
            for(var dev in deviceData) {
              deviceArray.push(dev);
              deviceAccess.push(deviceData[dev]);
            }
            for(var time in timesData) {
              timesLabels.push(time + " Uhr");
              timesAccess.push(timesData[time][0]);
              timesActions.push(timesData[time][1]);
            }
            timeAverageVisitors = new Array(timesLabels.length).fill(
              Math.round(report.content.sites.total.visitors / timesLabels.length),
              0, timesLabels.length
            );

            var deviceTable = {};
            for(var index = 0; index < deviceArray.length; index++) {
              deviceTable[deviceArray[index]] = deviceAccess[index];
            }

            setCD({
              dayArray: dayArray,
              dayBounceArray: dayBounceArray,
              dayConversions: dayConversions,
              dayNewArray: dayNewArray,
              dayVisitorArray: dayVisitorArray,
              deviceArray: deviceArray,
              deviceData: deviceData,
              deviceAccess: deviceAccess,
              timesData: timesData,
              timesAccess: timesAccess,
              timesActions: timesActions,
              timesLabels: timesLabels,
              timeAverageVisitors: timeAverageVisitors,
              dayReferrersSearch: dayReferrersSearch,
              deviceTable: deviceTable
            });

            setError('');
            setReport(result.data);
          }
          setLoading(false);
        });

    }, [props]);

    const updateName = (name) => {
      var token = localStorage.getItem('auth_token') || "unset";
      var site = localStorage.getItem('csid') || "unset";
      APIMan.sendRequest('renamereport.php?auth_token=' + token + 
      '&report=' + reportID + '&site_token=' + site + '&name=' + name, result => {
        if(result.error) {
          setErrorModalText(result.errorMessage);
          setErrorModal(true);
        }
      });
    }

    const deleteReport = () => {
      setLoading(true);
      var token = localStorage.getItem('auth_token') || "unset";
      var site = localStorage.getItem('csid') || "unset";
      APIMan.sendRequest('deletereport.php?auth_token=' + token + 
      '&report=' + reportID + '&site_token=' + site, result => {
        if(result.error) {
          setErrorModalText(result.errorMessage);
          setErrorModal(true);
          setLoading(false);
        }else {
          history.push('/home/reports');
        }
        setLoading(false);
      });
    }

    if(loading) {
      return <> <PageLoaderImage /> </>
    }

    if(error !== "") {
        return <>
            <div className="alert error">{error}</div>        
        </>
    }

    var kwIdeas = report.content.keywords.ideas, 
        kwTop = report.content.keywords.top, 
        kwPot = report.content.keywords.potenzial;

    if(kwIdeasShort) kwIdeas = kwIdeas.slice(0, 5);
    if(kwTopShort) kwTop = kwTop.slice(0, 5);
    if(kwPotShort) kwPot = kwPot.slice(0, 5);

    return <>

        <div id="overview"></div>

        {!props.viewOnly ? (
          <EditalComponent 
          value={report.name}
          placeholder='Bezeichnung'
          className="report-title"
          onSave={(val) => {updateName(val)}}
          saveOnEnter={true}
        />
        ) : (
          <h1>{report.name}</h1>
        )}
        {/* <pre>
            {JSON.stringify(report.content.events)}
        </pre> */}

        <p>
          Dieser Report startet am {report.start}, 
          dauert {report.duration} und endet am {report.end}.
        </p>

        <br /><br />
        <h2>Besucherverhalten</h2>
        <div className="table">
          <table>
            <thead>
              <tr>
                <td>Besucher</td>
                <td>Conversions</td>
                <td>Seitenaufrufe</td>
                <td>Aufenthaltszeit</td>
                <td>Absprungrate</td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{report.content.sites.total.visitors}<br /><small>Neu: {report.content.sites.total.newvisitors}</small></td>
                <td>{report.content.sites.conversions.total}</td>
                <td>{report.content.sites.total.views}<br /></td>
                <td>{Number(report.content.sites.total.time).toFixed(2)} s</td>
                <td>{Number(report.content.sites.total.bouncerate).toFixed(2)*100} %</td>
              </tr>
            </tbody>
          </table>
        </div>

        <br /><br />
        <h2>Conversions</h2>
        <div className="table">
          <table>
            <thead>
              <tr>
                <td>Name</td>
                <td>Anzahl</td>
              </tr>
            </thead>
            <tbody>
              {Object.keys(report['content']['sites']['conversions']['list']).map(element => {
                return <tr key={"f_" + element}>
                  <td>{report['content']['sites']['conversions']['list'][element].name}</td>
                  <td>{report['content']['sites']['conversions']['list'][element].conversions}</td>
                </tr>
              })}
            </tbody>
          </table>
        </div>

        <br /><br />
        <h2>Verlauf</h2>
        <Line data={{
          labels: cd.dayArray,
          datasets: [
            {
              label: 'Besucher gesamt',
              data: cd.dayVisitorArray,
              fill: false,
              backgroundColor: 'rgb(255, 99, 132)',
              borderColor: 'rgba(255, 99, 132, 0.2)',
            },
            {
              label: 'Neue Besucher',
              data: cd.dayNewArray,
              fill: false,
              backgroundColor: 'rgb(54, 162, 235)',
              borderColor: 'rgba(54, 162, 235, 0.2)',
              hidden: true,
            },
            {
              label: 'Abgesprungene Besucher',
              data: cd.dayBounceArray,
              fill: false,
              backgroundColor: 'rgba(255, 159, 64, 1)',
              borderColor: 'rgba(255, 159, 64, 0.2)',
              hidden: true,
            },
            {
              label: 'Conversions',
              data: cd.dayConversions,
              fill: false,
              backgroundColor: 'rgb(75, 192, 192)',
              borderColor: 'rgba(75, 192, 192, 0.2)',
              hidden: true,
            }
          ]
        }} options={{
          scales: {
            yAxes: [
              {
                ticks: {
                  beginAtZero: true,
                }
              }
            ]
          }
        }} />

        <div id="visitors"></div>

        <br /><br />
        <div className="flex flex-between">
          <h2>Zugriffs&shy;quellen</h2>
          <div className="toggle2">
            <div className={chartViewAccess ? 'active' : ''}
              onClick={() => setChartViewAccess(true)}>
              <BiBarChart />
            </div>
            <div className={!chartViewAccess ? 'active' : ''}
              onClick={() => setChartViewAccess(false)}>
              <BiLineChart />
            </div>
          </div>
        </div>
        <div style={{display: (chartViewAccess ? 'block' : 'none')}}>
          <Bar data={{
            labels: ['Suchmaschinen', 'Soziale Netzwerke', 'Direkte Zugriffe', 'Backlinks', 'Werbekampagnen'],
            datasets: [
              {
                label: 'Zugriffe',
                data: [
                  report['content']['sites']['total']['referrers']['searchengines'],
                  report['content']['sites']['total']['referrers']['socialmedia'],
                  report['content']['sites']['total']['referrers']['direct'],
                  report['content']['sites']['total']['referrers']['websites'],
                  report['content']['sites']['total']['referrers']['campaigns'],
                ],
                backgroundColor: 'rgba(255, 99, 132, 0.2)',
                borderColor: 'rgba(255, 99, 132, 1)',
                borderWidth: 1,
              }
            ]
          }} options={{
            scales: {
              yAxes: [
                {
                  ticks: {
                    beginAtZero: true,
                  }
                }
              ]
            }
          }} />
        </div>
        <div style={{display: (!chartViewAccess ? 'block' : 'none')}}>
          <Line data={{
            labels: cd.dayArray,
            datasets: [
              {
                label: 'Suchmaschinen',
                data: cd.dayReferrersSearch[0],
                fill: false,
                backgroundColor: 'rgb(255, 99, 132)',
                borderColor: 'rgba(255, 99, 132, 0.2)',
              },
              {
                label: 'Soziale Medien',
                data: cd.dayReferrersSearch[1],
                fill: false,
                backgroundColor: 'rgb(54, 162, 235)',
                borderColor: 'rgba(54, 162, 235, 0.2)',
              },
              {
                label: 'Direkt',
                data: cd.dayReferrersSearch[2],
                fill: false,
                backgroundColor: 'rgba(255, 159, 64, 1)',
                borderColor: 'rgba(255, 159, 64, 0.2)',
              },
              {
                label: 'Backlinks',
                data: cd.dayReferrersSearch[3],
                fill: false,
                backgroundColor: 'rgb(75, 192, 192)',
                borderColor: 'rgba(75, 192, 192, 0.2)',
              },
              {
                label: 'Werbekampagnen',
                data: cd.dayReferrersSearch[4],
                fill: false,
                backgroundColor: 'rgb(153, 102, 255)',
                borderColor: 'rgba(153, 102, 255, 0.2)',
              }
            ]
          }} options={{
            scales: {
              yAxes: [
                {
                  ticks: {
                    beginAtZero: true,
                  }
                }
              ]
            }
          }} />
        </div>

        <br /><br />
        <div className="flex flex-between">
          <h2>Endgeräte</h2>
          <div className="toggle2">
            <div className={chartViewDevice ? 'active' : ''}
              onClick={() => setChartViewDevice(true)}>
              <BiDoughnutChart />
            </div>
            <div className={!chartViewDevice ? 'active' : ''}
              onClick={() => setChartViewDevice(false)}>
              <BiTable />
            </div>
          </div>
        </div>
        <div style={{display: (chartViewDevice ? 'block' : 'none')}}>
          <Doughnut data={{
            labels: cd.deviceArray,
            datasets: [
              {
                label: 'Zugriffe',
                data: cd.deviceAccess,
                backgroundColor: [
                  'rgba(255, 99, 132, 0.2)',
                  'rgba(54, 162, 235, 0.2)',
                  'rgba(255, 206, 86, 0.2)',
                  'rgba(75, 192, 192, 0.2)',
                  'rgba(153, 102, 255, 0.2)',
                  'rgba(255, 159, 64, 0.2)',
                ],
                borderColor: [
                  'rgba(255, 99, 132, 1)',
                  'rgba(54, 162, 235, 1)',
                  'rgba(255, 206, 86, 1)',
                  'rgba(75, 192, 192, 1)',
                  'rgba(153, 102, 255, 1)',
                  'rgba(255, 159, 64, 1)',
                ],
              }
            ]
          }} options={{}} />
        </div>
        <div style={{display: (!chartViewDevice ? 'block' : 'none')}}>
          <div className="table">
            <table>
              <thead>
                <tr>
                  <td>Gerät</td>
                  <td>Zugriffe</td>
                </tr>
              </thead>
              <tbody>
                {Object.keys(cd.deviceTable).map((value, index) => {
                  return <tr key={"e_" + index}>
                    <td>{value}</td>
                    <td>{cd.deviceAccess[index]}</td>
                  </tr>
                })}
              </tbody>
            </table>
          </div>
        </div>

        <div id="times"></div>

        <br /><br />
        <h2>Aktive Zeiten</h2>
        <Bar data={{
          labels: cd.timesLabels,
          datasets: [
            {
              label: 'Zugriffe',
              data: cd.timesAccess,
              backgroundColor: 'rgb(255, 99, 132)',
            },
          ]
        }} options={{
          scales: {
            yAxes: [
              {
                ticks: {
                  beginAtZero: true,
                }
              }
            ]
          }
        }} />

        <br /><br />
        <h2>Aktive Tage</h2>
        <Bar data={{
          labels: ['Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag', 'Sonntag'],
          datasets: [
            {
              label: 'Zugriffe',
              data: report.content.sites.total.days,
              backgroundColor: 'rgb(255, 99, 132)',
            },
          ]
        }} options={{
          scales: {
            yAxes: [
              {
                ticks: {
                  beginAtZero: true,
                }
              }
            ]
          }
        }} />

        <div id="keywords"></div>

        <br /><br />
        <div className="flex flex-between">
          <h2>Top Keywords</h2>
          <div className="toggle2">
            <div className={kwTopShort ? 'active' : ''}
              onClick={() => setKwTopShort(true)}>
              <BiTable />
            </div>
            <div className={!kwTopShort ? 'active' : ''}
              onClick={() => setKwTopShort(false)}>
              <BiSpreadsheet />
            </div>
          </div>
        </div>

        <div className="table">
          <table>
            <thead>
              <tr>
                <td>Keyword</td>
                <td>Position</td>
                <td>Konkurrenz</td>
                <td>Suchvolumen</td>
                <td>CPC</td>
              </tr>
            </thead>
            <tbody>
              {kwTop.map(item => {
                return <tr key={"d_" + item}>
                  <td>{item}</td>
                  <td>{report.content.keywords.list[item].position}</td>
                  <td>{Math.round(report.content.keywords.list[item].competitordensity*100)} %</td>
                  <td>{report.content.keywords.list[item].sevo}</td>
                  <td>{report.content.keywords.list[item].cpc}</td>
                </tr>
              })}
            </tbody>
          </table>
        </div>
        {!props.viewOnly && <p><SupportWindow link="/support/keywords">
            Mehr Informationen über Keywords
          </SupportWindow></p>}

        <br /><br />
        <div className="flex flex-between">
          <h2>Potenzielle Keywords</h2>
          <div className="toggle2">
            <div className={kwPotShort ? 'active' : ''}
              onClick={() => setKwPotShort(true)}>
              <BiTable />
            </div>
            <div className={!kwPotShort ? 'active' : ''}
              onClick={() => setKwPotShort(false)}>
              <BiSpreadsheet />
            </div>
          </div>
        </div>

        <div className="table">
          <table>
            <thead>
              <tr>
                <td>Keyword</td>
                <td>Position</td>
                <td>Konkurrenz</td>
                <td>Suchvolumen</td>
                <td>CPC</td>
              </tr>
            </thead>
            <tbody>
              {kwPot.map(item => {
                return <tr key={"g_" + item}>
                  <td>{item}</td>
                  <td>{report.content.keywords.list[item].position}</td>
                  <td>{Math.round(report.content.keywords.list[item].competitordensity*100)} %</td>
                  <td>{report.content.keywords.list[item].sevo}</td>
                  <td>{report.content.keywords.list[item].cpc}</td>
                </tr>
              })}
            </tbody>
          </table>
        </div>
        {!props.viewOnly && <p><SupportWindow link="/support/keywords">
            Mehr Informationen über Keywords
          </SupportWindow></p>}
        
        <br /><br />
        <div className="flex flex-between">
          <h2>Keyword Ideen</h2>
          <div className="toggle2">
            <div className={kwIdeasShort ? 'active' : ''}
              onClick={() => setKwIdeasShort(true)}>
              <BiTable />
            </div>
            <div className={!kwIdeasShort ? 'active' : ''}
              onClick={() => setKwIdeasShort(false)}>
              <BiSpreadsheet />
            </div>
          </div>
        </div>

        <div className="table">
          <table>
            <thead>
              <tr>
                <td>Keyword</td>
                <td>Position</td>
                <td>Konkurrenz</td>
                <td>Suchvolumen</td>
                <td>CPC</td>
              </tr>
            </thead>
            <tbody>
              {kwIdeas.map(item => {
                  return <tr key={"c_" + item}>
                    <td><span className={"kw_light " + report.content.keywords.list[item].light}></span>{item}</td>
                    <td>{report.content.keywords.list[item].position}</td>
                    <td>{Math.round(report.content.keywords.list[item].competitordensity*100)} %</td>
                    <td>{report.content.keywords.list[item].sevo}</td>
                    <td>{report.content.keywords.list[item].cpc}</td>
                </tr>
              })}
            </tbody>
          </table>
        </div>
        {!props.viewOnly && <p><SupportWindow link="/support/keywords">
            Mehr Informationen über Keywords
          </SupportWindow></p>}

        <div id="sites"></div>

        <br /><br />
        <h2>Meistbesuchte Seiten</h2>
        <div className="table">
          <table>
            <thead>
              <tr>
                <td>Seite</td>
                <td>Besucher</td>
                <td>Absprungrate</td>
                <td>Aufenthaltszeit</td>
                {!props.viewOnly && <td>Wachstum</td>}
              </tr>
            </thead>
            <tbody>
              {Object.keys(report.content.sites.sites.visitors).map(index => {
                var site = report.content.sites.sites.visitors[index];
                return <tr key={"b_" + index}>
                  <td>{site}</td>
                  <td>{report.content.sites.sites.list[site].visitors}</td>
                  <td>{Math.round(report.content.sites.sites.list[site].bouncerate*100)} %</td>
                  <td>{Math.round(report.content.sites.sites.list[site].avgtime)} s</td>
                  {!props.viewOnly && <>
                    {Math.round(report.content.sites.sites.list[site].grown*100) >= 100 && // very good
                    <td><span className="kw_light lightgreen">
                    {Math.round(report.content.sites.sites.list[site].grown*100)} %</span></td>}
                  {Math.round(report.content.sites.sites.list[site].grown*100) > 0 &&
                    Math.round(report.content.sites.sites.list[site].grown*100) < 100 && // good
                    <td><span className="kw_light green">
                    {Math.round(report.content.sites.sites.list[site].grown*100)} %</span></td>}
                  {Math.round(report.content.sites.sites.list[site].grown*100) === 0 && // ok
                    <td><span className="kw_light orange">
                    {Math.round(report.content.sites.sites.list[site].grown*100)} %</span></td>}
                  {Math.round(report.content.sites.sites.list[site].grown*100) < 0 && // bad
                    <td><span className="kw_light red">
                      {Math.round(report.content.sites.sites.list[site].grown*100)} %</span></td>}
                  </>}
                </tr>
              })}
            </tbody>
          </table>
        </div>

        <br /><br />
        <h2>Gewachsene Seiten</h2>
        <div className="table">
          <table>
            <thead>
              <tr>
                <td>Seite</td>
                <td>Besucher</td>
                <td>Absprungrate</td>
                <td>Aufenthaltszeit</td>
                {!props.viewOnly && <td>Wachstum</td>}
              </tr>
            </thead>
            <tbody>
              {Object.keys(report.content.sites.sites.grown).map(index => {
                var site = report.content.sites.sites.grown[index];
                return <tr key={"a_" + index}>
                  <td>{site}</td>
                  <td>{report.content.sites.sites.list[site].visitors}</td>
                  <td>{Math.round(report.content.sites.sites.list[site].bouncerate*100)} %</td>
                  <td>{Math.round(report.content.sites.sites.list[site].avgtime)} s</td>
                  {!props.viewOnly && <>
                    {Math.round(report.content.sites.sites.list[site].grown*100) >= 100 && // very good
                    <td><span className="kw_light lightgreen">
                    {Math.round(report.content.sites.sites.list[site].grown*100)} %</span></td>}
                  {Math.round(report.content.sites.sites.list[site].grown*100) > 0 &&
                    Math.round(report.content.sites.sites.list[site].grown*100) < 100 && // good
                    <td><span className="kw_light green">
                    {Math.round(report.content.sites.sites.list[site].grown*100)} %</span></td>}
                  {Math.round(report.content.sites.sites.list[site].grown*100) === 0 && // ok
                    <td><span className="kw_light orange">
                    {Math.round(report.content.sites.sites.list[site].grown*100)} %</span></td>}
                  {Math.round(report.content.sites.sites.list[site].grown*100) < 0 && // bad
                    <td><span className="kw_light red">
                      {Math.round(report.content.sites.sites.list[site].grown*100)} %</span></td>}
                  </>}
                </tr>
              })}
            </tbody>
          </table>
        </div>

        {!props.viewOnly && <>
          <br />

          <button className="btn simple" onClick={deleteReport}>
            Report löschen
          </button>
          <small>Diese Aktion kann nicht Rückgängig gemacht werden.</small>
        </>}

        <Modal open={errorModal}>
          <ModalCloser onClick={() => setErrorModal(false)} />
          <ModalContent>
            <h2>Fehler</h2>
            <p>
              {errorModalText}
            </p>
          </ModalContent>
        </Modal>
    
    </>

}

export default Report;