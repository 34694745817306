import { useEffect, useState } from "react";
import { BsBullseye, BsChatDotsFill, BsCheckBox, BsCloudFill, BsFillBarChartFill, BsInfoCircle } from "react-icons/bs";
import { Link } from "react-router-dom";
import APIMan from "../../data/APIMan";
import { PageLoaderImage } from "../Pageloader";
import Tooltip from "../Tooltip";


const Home = (props) => {

    const [serverState, setServerState] = useState(0);
    const [reports, setReports] = useState(0);
    const [goals, setGoals] = useState(0);
    const [notifications, setNotifications] = useState(0);

    useEffect(() => {

        checkServer('https://todo.com', 2000, () => {
            setServerState(2);
        }, () => {
            setServerState(1)
        });

        var token = localStorage.getItem('auth_token') || 'unset';
        var site = localStorage.getItem('csid') || 'unset';
        APIMan.sendRequest('dashboard.php?auth_token=' + token + '&site_token=' + site, result => {
            if(!result.error) {
                setReports(result.data.reports);
                setGoals(result.data.goals);
                setNotifications(result.data.notifications);
            }
        });

    }, [props]);

    const checkServer = (url, timeout, success, fail) => {
        const controller = new AbortController();
        const signal = controller.signal;
        const options = { mode: 'no-cors', signal };
        return fetch(url, options)
          .then(setTimeout(() => { controller.abort() }, timeout))
          .then(success)
          .catch(fail);
    }

    return <>
    
        <h1>Übersicht</h1>

        <div className="flex-grid">

            <div className="row">
                <DashBox to='/home/reports' title="Reports" icon={<BsFillBarChartFill />}>
                <span className="marked">{reports}</span>
                </DashBox>
            </div>

            <div className="row">
                <DashBox to='/home/goals' title="Ziele" icon={<BsBullseye />}>
                <span className="marked">{goals}</span>
                </DashBox>
            </div>

            <div className="row">
                <DashBox title="Status" icon={<BsCloudFill />}>
                    <span className="marked">
                        {serverState === 0 && <span className="kw_light orange">Lädt...</span>}
                        {serverState === 1 && <span className="kw_light red">Offline</span>}
                        {serverState === 2 && <span className="kw_light lightgreen">Online</span>}
                    </span>
                </DashBox>
            </div>

            <div className="row">
                <DashBox to='/profile/notifications' title="Benachrichtigungen" icon={<BsChatDotsFill />}>
                <span className="marked">{notifications}</span>
                </DashBox>
            </div>

        </div>

        <div className="flex-grid">

            <div className="row medium">
                <DashBox>
                    <h3>Definierte Ziele</h3>
                    <DashGoals />
                </DashBox>
            </div>

            <div className="row medium">
                <DashBox>
                    <h3>Letzte Reports</h3>
                    <DashReports />
                </DashBox>
            </div>

        </div>
    
    </>

}

export function DashBox(props) {
    if(props.to) {
        return <>
        <Link to={props.to ? props.to : ''}>
            <div className={"dash-box " + (props.className ? props.className : "")}>
                <div className="icon">{props.icon}</div>    
                <div className="content">{props.children}</div>     
                <div className="title">{props.title}</div>            
            </div>
        </Link>
        </>
    }
    return <>
        <div className={"dash-box " + (props.className ? props.className : "")}>
            <div className="icon">{props.icon}</div>    
            <div className="content">{props.children}</div>     
            <div className="title">{props.title}</div>            
        </div>
    </>

}

function DashReports(props) {
    const [reports, setReports] = useState([]);
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        reloadTable();    
    }, [props]);

    const reloadTable = () => {
        setLoading(true);
        setError('');
        setReports([]);
        var token = localStorage.getItem('auth_token') || "unset";
        var site = localStorage.getItem('csid') || "unset";
        APIMan.sendRequest('getreports.php?auth_token=' + token + '&site_token=' + site 
        + '&offset=0&amount=6', result => {
            if(result.error) {
                setError(result.errorMessage);
            }else {
                setError('');
                setReports(result.data.reports);
            }
            setLoading(false);
        });
    }

    if(loading) {
        return <> <PageLoaderImage /> </>
    }

    if(error !== "") {
        return <>
            <div className="alert error">{error}</div>        
        </>
    }

    return <>

        <div className="table">
        <table>
            <thead>
                <tr>
                    <td>Bezeichnung</td>
                    <td>Von</td>
                    <td></td>
                    <td>Bis</td>
                </tr>
            </thead>
            <tbody>
                {reports.length >= 0 && reports.map(report => (
                    <tr key={report.id}>
                        <td><Link to={(report.type === "3" ? '/home/reports/' : '/home/reports/' + report.token)}>{report.name}</Link></td>
                        <td>{report.start}</td>
                        <td><span className="duration">{report.duration}</span></td>
                        <td>{report.end}</td>
                    </tr>
                ))}
            </tbody>
        </table>
        </div>
    
        <Link to="/home/reports" className="btn simple">
            Alle Reports anzeigen
        </Link>

    </>

}

function DashGoals(props) {
    const [goals, setGoals] = useState([]);
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(true);
    
    useEffect(() => {
        reloadTable();    
    }, [props]);

    const reloadTable = () => {
        setLoading(true);
        setError('');
        setGoals([]);

        var startDate = new Date();
        startDate.setMonth(startDate.getMonth() - 12);
        var startDateString = startDate.getUTCFullYear() + "-" + zeronumber(startDate.getUTCMonth()+1) 
        + "-" + zeronumber(startDate.getUTCDate());
        var endDate = new Date();
        var endDateString = endDate.getUTCFullYear() + "-" + zeronumber(endDate.getUTCMonth()+1) 
        + "-" + zeronumber(endDate.getUTCDate());

        var token = localStorage.getItem('auth_token') || "unset";
        var site = localStorage.getItem('csid') || "unset";
        APIMan.sendRequest('getgoals.php?auth_token=' + token + '&site_token=' + site 
        + '&offset=0&amount=6&start=' + startDateString + '&end=' + endDateString, result => {
            if(result.error) {
                setError(result.errorMessage);
            }else {
                setError('');
                setGoals(result.data.goals);
            }
            setLoading(false);
        });
    }

    const zeronumber = (input) => {
        return (
            input > 9 ? input : "0" + input
        );
    }

    if(loading) {
        return <PageLoaderImage />
    }

    if(error !== "") {
        return <>
            <div className="alert error">{error}</div>        
        </>
    }

    return <>
        {goals.map(item => (
            <div className="mini-goal">
                <span className="g-title">{item.data.data.title}</span>
                {item.data.type === 'note' && <span className="g-desc">{item.data.data.text}</span>}
                {item.data.type === 'visitors' && <span className="g-desc">
                    Erreiche {item.data.data.count} Besucher an einem Tag.
                    {(item.data.data.date.day !== '*' || item.data.data.date.month !== '*' ||
                    item.data.data.date.weekday !== '*') && <Tooltip content={<>
                        Tag: {item.data.data.date.day}<br />
                        Monat: {item.data.data.date.month}<br />
                        Wochentag: {item.data.data.date.weekday}<br />
                    </>}>
                        &nbsp;<BsInfoCircle />    
                    </Tooltip>}
                </span>}
                {item.progress === "100" && <span className="finished">
                    <BsCheckBox /> Dieses Ziel ist als erledigt markiert
                </span>}
            </div>
        ))}
    
        <Link to="/home/goals" className="btn simple">
            Alle Ziele anzeigen
        </Link>
    </>
}

export default Home;